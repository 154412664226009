import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import salaried_img from "../../../img/delta/salaried_img.png";
import self_emp from "../../../img/delta/self_emp.png";
import user_info_icon from "../../../img/delta/user_info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const initValError = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const errorDataValue = {
  monthlyIncome: "Please Enter Monthly Income",
  companyName: "Please Enter Company Name",
};
const UserInformation = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [empType, setEmpType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setSignUpData({
        ...addr,
        empType: applicationDetails?.empType,
        monthlyIncome: applicationDetails?.monthlyIncome,
        companyName: applicationDetails?.companyName,
      });
      setEmpType(applicationDetails?.empType);
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    setInstituteErrorMessage("");
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setCurrentStep("4");
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
          // history.push("/customerDashboard");
        } else {
          setInstituteErrorMessage("Please Enter Valid Pan Card.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    return isValid;
  };
  const handleProceed = async () => {
    if (empType) {
      if (handleValidate()) {
        const body = {
          ...signUpData,
          empType: empType,
          _id: applicationId,
        };
        console.log("body", body);
        setIsLoading(true);
        const resp = (await dispatch(updateApplicationAPI(body))) as any;
        setIsLoading(false);
        if (resp?.payload?.success) {
          // getAppDetailsIdCall();
          props?.nextStep();
        } else {
          setErrorMessage("Please enter valid details!");
        }
      } else {
        setErrorMessage("Please Enter Valid Address Details.");
      }
    } else {
      setErrorMessage("Please select Employment Type");
    }
    // props?.nextStep();
  };
  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
    console.log("allInstitutesList", allInstitutesList);
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  const selectEmptype = (val: any) => {
    setEmpType(val);
    console.log("valval", val);
  };
  console.log("empType", empType);
  return (
    <>
      <div className="create-new-application-sec user-emp-details-sec">
        {isLoading && <Loader />}
        <>
          <div>
            <div className="user_info_icon_sec_top">
              <img src={user_info_icon} />
              <p>Employment Details</p>
            </div>

            <div className="row">
              <h5>Are you Salaried or Self Employed?</h5>
              <div className="all-pan-address-row-all">
                <div>
                  <div className="d-flex">
                    <div>
                      <img src={self_emp} />
                    </div>
                    <div>
                      <h6>Salaried</h6>
                      <p>I work for a company and get monthly salary</p>
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      onClick={() => {
                        selectEmptype("salaried");
                      }}
                      id="Rented22"
                      name="empType"
                      value="salaried"
                      checked={empType === "salaried" ? true : false}
                    />
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    <div>
                      <img src={salaried_img} />{" "}
                    </div>
                    <div>
                      <h6>Self Employed</h6>
                      <p>I work for My Self and get monthly salary</p>
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="Rented22"
                      name="empType"
                      value="self"
                      onClick={() => {
                        selectEmptype("self");
                      }}
                      checked={empType === "self" ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
            {empType && (
              <div className="student-info-values">
                <div className="row">
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        Full Legal Name of Current Company{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="companyName"
                        value={signUpData?.companyName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.companyName ? (
                          <>{errorDataValue?.companyName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        Monthly Income (In Hand){" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="monthlyIncome"
                        value={signUpData?.monthlyIncome}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.monthlyIncome ? (
                          <>{errorDataValue?.monthlyIncome}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            )}
          </div>
          {instituteerrorMessage && (
            <p className="error_message text_left">{instituteerrorMessage}</p>
          )}
        </>
        {empType && (
          <div className="actions-btn-sec">
            <div className="deltape-action-btns">
              <button onClick={props?.prevStep} className="cancel-btn">
                Back
              </button>
              <button onClick={handleProceed} className="confirm-btn">
                Proceed
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInformation;
