import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import step1 from "../../../img/delta/image_step1.png";
import user_info_icon from "../../../img/delta/user_info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  city: undefined,
  line1: undefined,
  line2: undefined,
  pincode: undefined,
  state: undefined,
  street: undefined,
};
const initValError = {
  city: undefined,
  line1: undefined,
  pincode: undefined,
  state: undefined,
  street: undefined,
};
const errorDataValue = {
  line1: "Please Enter Address line 1",
  city: "Please Enter City",
  line2: "Please Enter Address line 2",
  pincode: "Please Enter Pin Code",
  state: "Please Enter State",
  street: "Please Enter Street",
};
const UserInformation = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [address1, setAddress1] = useState({} as any);
  const [selectedAddress, setSelectedAddress] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [residentType, setResidentType] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    getAppDetailsIdCall();
  }, []);

  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setSignUpData({
        ...addr,
        ...applicationDetails?.address1,
      });
      setAddress1(applicationDetails?.address1);
      setResidentType(applicationDetails?.residentType);
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);
  const getAppDetailsIdCall = async () => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const editAddressDetails = (adds: any) => {
    setSelectedAddress(adds);
    setIsEditAddress(!isEditAddress);
  };
  const cancelAddressDetails = (adds: any) => {
    setIsEditAddress(!isEditAddress);
  };
  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    return isValid;
  };

  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeValue1 = async (e: any) => {
    let { name, value } = e.target;
    setResidentType(value);
    setErrorMessage("");
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    setInstituteErrorMessage("");
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setCurrentStep("4");
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
          // history.push("/customerDashboard");
        } else {
          setInstituteErrorMessage("Please Enter Valid Pan Card.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          // setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
    console.log("allInstitutesList", allInstitutesList);
  };

  const saveAddressDetails = async () => {
    if (handleValidate()) {
      const body = {
        address1: { ...signUpData },
        _id: applicationId,
      };
      console.log("body", body);
      setIsLoading(true);
      const resp = (await dispatch(updateApplicationAPI(body))) as any;
      setIsLoading(false);
      if (resp?.payload?.success) {
        getAppDetailsIdCall();
        setIsEditAddress(!isEditAddress);
      } else {
        setErrorMessage("Please enter valid details!");
      }
    } else {
      setShowErrorMessage(true);
    }

    // props?.nextStep();
  };

  const handleProceed = async () => {
    if (residentType) {
      if (handleValidate()) {
        const body = {
          residentType: residentType,
          _id: applicationId,
        };
        console.log("body", body);
        setIsLoading(true);
        const resp = (await dispatch(updateApplicationAPI(body))) as any;
        setIsLoading(false);
        if (resp?.payload?.success) {
          getAppDetailsIdCall();
          props?.nextStep();
        } else {
          setErrorMessage("Please enter valid details!");
        }
      } else {
        setErrorMessage("Please Enter Valid Address Details.");
      }
    } else {
      setErrorMessage("Please select Resident Type");
    }
    // props?.nextStep();
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  return (
    <>
      <div className="create-new-application-sec pan-card-address-sec">
        {isLoading && <Loader />}
        {isEditAddress ? (
          <>
            <div className="student-info-values">
              <div className="row">
                <div className="student-info-row">
                  <Form.Group className="signup-md4" controlId="email">
                    <Form.Label>
                      Address Line 1 <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="line1"
                      value={signUpData?.line1}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.line1 ? <>{errorDataValue?.line1}</> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="student-info-row">
                  <Form.Group className="signup-md4" controlId="email">
                    <Form.Label>
                      Address Line 2 <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="line2"
                      value={signUpData?.line2}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.line2 ? <>{errorDataValue?.line2}</> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="column2">
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        Colony/Street <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        value={signUpData?.street}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.street ? (
                          <>{errorDataValue?.street}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        City <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={signUpData?.city}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.city ? <>{errorDataValue?.city}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="column2">
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        State <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        value={signUpData?.state}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.state ? <>{errorDataValue?.state}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="signup-md4" controlId="email">
                      <Form.Label>
                        Pincode <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        maxLength={6}
                        value={signUpData?.pincode}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.pincode ? (
                          <>{errorDataValue?.pincode}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions-btn-sec">
              <div className="deltape-action-btns">
                <button onClick={cancelAddressDetails} className="cancel-btn">
                  cancel
                </button>
                <button onClick={saveAddressDetails} className="confirm-btn">
                  Save
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <div className="user_info_icon_sec_top">
                <img src={user_info_icon} />
                <p>Residential Details</p>
                <p>Are all these details correct and belong to you?</p>
              </div>

              <div>
                <div className="row all-pan-address-row-sec">
                  <Form.Group className="signup-md4" controlId="email">
                    <Form.Label>
                      Select Address <span className="mand-sign-field">*</span>{" "}
                    </Form.Label>
                    <div className="all-pan-address-row-all">
                      <div>
                        <div>
                          <label htmlFor="Rented22">
                            {address1?.line1},{address1?.line2},
                            {address1?.street},{address1?.city},
                            {address1?.state},{address1?.pincode}
                          </label>{" "}
                          <button
                            onClick={() => editAddressDetails(address1)}
                            className="cancel-btn"
                          >
                            Edit
                          </button>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="Rented22"
                            name="select_address_user"
                            value="Campus 8B, Sarjapur - Marathalli Outer Ring Road, Varthur Hobli, RMZ Ecoworld, Bengaluru, Karnataka 560103"
                          />
                        </div>
                      </div>
                      {/* <div>
                        <div>
                          <label htmlFor="Self_Owned22">
                            Campus 8B, Sarjapur - Marathalli Outer Ring Road,
                            Varthur Hobli, RMZ Ecoworld, Bengaluru, Karnataka
                            560103
                          </label>{" "}
                          <button
                            onClick={() => editAddressDetails(address1)}
                            className="cancel-btn"
                          >
                            Edit
                          </button>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="Self_Owned22"
                            name="select_address_user"
                            value="Campus 8B, Sarjapur - Marathalli Outer Ring Road, Varthur Hobli, RMZ Ecoworld, Bengaluru, Karnataka 560103"
                          />
                        </div>
                      </div> */}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row what-Resident-Type">
                <Form.Group className="signup-md" controlId="email">
                  <Form.Label>
                    What is your Resident Type{" "}
                    <span className="mand-sign-field">*</span>{" "}
                  </Form.Label>
                  <div>
                    <input
                      type="radio"
                      id="Rented"
                      name="residentType"
                      value="Rented"
                      checked={residentType === "Rented" ? true : false}
                      onChange={(e) => handleChangeValue1(e)}
                    />
                      <label htmlFor="Rented">Rented</label> {" "}
                    <input
                      type="radio"
                      id="Self Owned"
                      name="residentType"
                      value="Self"
                      checked={residentType === "Self" ? true : false}
                      onChange={(e) => handleChangeValue1(e)}
                    />
                      <label htmlFor="Self Owned">Self Owned</label> {" "}
                    <input
                      type="radio"
                      id="Others"
                      name="residentType"
                      value="Others"
                      checked={residentType === "Others" ? true : false}
                      onChange={(e) => handleChangeValue1(e)}
                    />
                      <label htmlFor="Others">Others</label>
                  </div>
                </Form.Group>
              </div>
            </div>
            {errorMessage && (
              <p className="error_message text_left">{errorMessage}</p>
            )}

            <div className="actions-btn-sec">
              <div className="deltape-action-btns">
                <button onClick={props?.prevStep} className="cancel-btn">
                  Back
                </button>
                <button onClick={handleProceed} className="confirm-btn">
                  Proceed
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserInformation;
