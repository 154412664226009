import React, { useEffect, useState } from "react";
import { DeltaAutoStyled } from "./DeltaAuto.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../Scenes/common";
import Payments from "../Students/StudentModules/Payments";
import {
  getStudentDetailsAPI,
  getAllFeeComponentsAPI,
  getAllDirectPaymentsAPI,
} from "../../redux/slices/StudentManagement/StudentManagementService";
import Select from "react-select";
import { Tabs } from "antd";
import Overdue from "./Overdue";
const DeltaAuto = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpData, setSignUpData] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isParentSite } = useSelector(
    (state: any) => state?.auth
  );
  console.log("user", user);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (isParentSite) {
      dispatch(getAllDirectPaymentsAPI({ mobileNumber: user?.mobileNumber }));
    } else {
      dispatch(getAllDirectPaymentsAPI({ instituteId: user?._id }));
    }
  }, []);
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const handleChangeRole = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["reportType"]: selectedOption?.label,
    });
  };
  const handleChangeStatus = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["status"]: selectedOption?.label,
    });
  };

  return (
    <>
      <DeltaAutoStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div className="contact">
              <div className="row">
                <div className="col-md-6">
                  <h2>Delta Auto</h2>
                </div>
              </div>
            </div>
            <div className="delata-tabs-sec">
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: "Dashboard",
                    key: "1",
                    children: <Overdue section="Dashboard" />,
                  },
                  {
                    label: "Overdue",
                    key: "2",
                    children: <Overdue section="Overdue" />,
                  },
                  {
                    label: "Cashflow",
                    key: "3",
                    children: <Overdue section="Cashflow" />,
                  },
                  {
                    label: "Subscriptions",
                    key: "4",
                    children: <Overdue section="Subscriptions" />,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </DeltaAutoStyled>
    </>
  );
};

export default DeltaAuto;
