import React, { useEffect, useState } from "react";
import { StudentDetailsStyled } from "./StudentDetails.styled";
import type { MenuProps } from "antd";
import { Button, Dropdown, Space } from "antd";

import { Row, Col, Form, CardDeck, CardColumns } from "react-bootstrap";
import Select from "react-select";
import {
  getStudentDetailsAPI,
  getAllFeeComponentsAPI,
  getAllDirectPaymentsAPI,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../../components/Common/Loader";
import CustomModal from "../../../components/CustomModel";
import EditStudentDetails from "../StudentDetails/EditStudentDetails";
// import ProductCard from "./../../components/ProductCard";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { acadamicYearsList, classesList, admissionList } from "../../../config";

import moment from "moment";
import { triggerMixpanelEvent } from "./../../../Scenes/common";
import Components from "./../StudentModules/Components";
import Payments from "./../StudentModules/Payments";
import FeeStructure from "./../StudentModules/FeeStructure";
import BankTransfer from "./../StudentModules/BankTransfer";
import DropOffStudent from "../DropOffStudent";
import CreatePaymentLink from "../CreatePaymentLink/CreatePaymentLink";
import SendRegistrationLink from "../SendRegistrationLink/SendRegistrationLink";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
const StudentDetails = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [newAdmission, setnewAdmission] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [editStudentModel, setEditStudentModel] = useState(false);
  const [showDropOffStudent, setShowDropOffStudent] = useState(false);
  const [showSendRegistrationLink, setShowSendRegistrationLink] =
    useState(false);
  const [showCreatePaymentLink, setShowCreatePaymentLink] = useState(false);

  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const { studentId } = props?.match?.params;

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { studentDetails, allFeeComponents, allDirectPayments } = useSelector(
    (state: any) => state?.studentManagement
  );
  console.log("studentDetails", studentDetails);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (studentId) {
      const body = {
        studentId,
      };
      dispatch(getStudentDetailsAPI(studentId));
      dispatch(getAllFeeComponentsAPI(body));
      dispatch(getAllDirectPaymentsAPI(body));
    }
  }, []);
  const handleCloseSuccess = () => {
    dispatch(getStudentDetailsAPI(studentId));
    setShowSuccessMessage(true);
    setEditStudentModel(false);
  };

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const getTotalAmount = () => {
    var totalM = 0;
    allFeeComponents?.map((item: any) => {
      totalM = parseInt(item?.FeeAmount) + totalM;
    });
    return totalM;
  };
  const getPaidAmount = () => {
    var totalM = 0;
    // transpotaionFees
    // tuitionFees
    // addmissionFees
    allDirectPayments?.map((item: any) => {
      if (item?.transpotaionFees) {
        totalM = parseInt(item?.transpotaionFees) + totalM;
      } else if (item?.tuitionFees) {
        totalM = parseInt(item?.tuitionFees) + totalM;
      } else if (item?.addmissionFees) {
        totalM = parseInt(item?.addmissionFees) + totalM;
      }
    });
    return totalM;
  };

  const handleCancel = () => {
    history.push("/students");
  };
  const handleEdit = (id: any) => {
    setCurrentStep(`createStudent/${id}`);
  };
  const handleEditStudent = () => {
    setEditStudentModel(true);
  };
  const handleEditClose = () => {
    setEditStudentModel(false);
    setShowDropOffStudent(false);
    setShowCreatePaymentLink(false);
    setShowSendRegistrationLink(false);
  };
  const onTabChange = (key: string) => {
    console.log(key);
  };

  const StudentAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "DropOff") {
      setShowDropOffStudent(true);
    } else if (actionN === "PaymentLink") {
      setShowCreatePaymentLink(true);
    } else if (actionN === "RegistrationLink") {
      setShowSendRegistrationLink(true);
    }
  };
  const items1: TabsProps["items"] = [
    {
      key: "1",
      label: "Components",
      children: <Components studentId={studentId} />,
    },
    {
      key: "2",
      label: "Fee structure(s)",
      children: <FeeStructure studentId={studentId} />,
    },
    {
      key: "3",
      label: "Payments",
      children: <Payments studentId={studentId} />,
    },
    {
      key: "4",
      label: "Bank transfer",
      children: <BankTransfer studentId={studentId} />,
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "DropOff",
      label: (
        <span className="drop-down-single-item-sec">Mark as Drop Off</span>
      ),
    },
    {
      key: "PaymentLink",
      label: (
        <span className="drop-down-single-item-sec">Create Payment Link</span>
      ),
    },
    {
      key: "RegistrationLink",
      label: (
        <span className="drop-down-single-item-sec">Send auto pay link</span>
      ),
    },
    {
      key: "RegistrationLink",
      label: (
        <span className="drop-down-single-item-sec">
          Send Unique account details
        </span>
      ),
    },
    {
      key: "RegistrationLink",
      label: (
        <span className="drop-down-single-item-sec">Download unique QR</span>
      ),
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];

  // const itemsListv: MenuProps["itemsListv"] = [
  //   {
  //     key: "DropOff",
  //     label: (
  //       <span className="drop-down-single-item-sec">Mark as Drop Off</span>
  //     ),
  //   },

  //   {
  //     key: "PaymentLink",
  //     label: (
  //       <span className="drop-down-single-item-sec">Create Payment Link</span>
  //     ),
  //   },

  //   {
  //     key: "RegistrationLink",
  //     label: (
  //       <span className="drop-down-single-item-sec">
  //         Send Flex Registration Link
  //       </span>
  //     ),
  //   },

  //   {
  //     key: "delete",
  //     label: <span className="drop-down-single-item-sec">Delete</span>,
  //   },
  // ] as any;
  return (
    <>
      <StudentDetailsStyled>
        <div className="create-new-institute-sec">
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div>
                <BreadCrumb
                  list={[
                    { title: "Students", link: "/students" },
                    { title: studentDetails?.studentName },
                  ]}
                />
                <div className="profileinfoHeader">
                  <div>
                    <span className="edit-p-text">Student Details</span>

                    <div>
                      {/* <span className="profileEdit" onClick={handleBulkUpload}>
                        Bulk Upload &nbsp;
                        <img src={upload_icon} />
                      </span> */}
                      <div className="dots-space-sec">
                        <Space direction="vertical">
                          <Space wrap>
                            <Dropdown
                              menu={{
                                items,
                                onClick: ({ key }) => {
                                  StudentAction("itemId", key);
                                },
                              }}
                              placement="bottomLeft"
                            >
                              <span> ...</span>
                            </Dropdown>
                          </Space>
                        </Space>
                      </div>
                      {/* <span className="profileEdit">...</span> */}
                    </div>
                  </div>
                  <p>View Student Information </p>
                </div>
              </div>

              <div className="student-details-sec-con-de">
                <div className="student-details-rows">
                  <div className="student-details-row">
                    <span className="s-name-key">Student Name:</span>
                    <span className="s-name-value">
                      {studentDetails?.studentName}
                    </span>
                  </div>
                  <div className="student-details-row">
                    <span className="s-name-key">Grade:</span>
                    <span className="s-name-value">
                      {studentDetails?.studentGrade}
                    </span>
                  </div>
                  <div className="student-details-row">
                    <span className="s-name-key">Academic Year:</span>
                    <span className="s-name-value">
                      {studentDetails?.academicYear}
                    </span>
                  </div>
                  {/* <div></div> */}
                  <div className="student-details-row">
                    <span className="s-name-key">Roll Number:</span>
                    <span className="s-name-value">
                      {studentDetails?.rollNumber}
                    </span>
                  </div>
                  <div className="student-details-row">
                    <span className="s-name-key">New Admission:</span>
                    <span className="s-name-value">
                      {studentDetails?.newAdmission}
                    </span>
                  </div>
                  {/* <div></div> */}
                  <div className="student-details-row">
                    <span className="s-name-key">Primary Contact Name:</span>
                    <span className="s-name-value">
                      {studentDetails?.primaryContactName}
                    </span>
                  </div>
                  <div className="student-details-row">
                    <span className="s-name-key">Primary Contact Number:</span>
                    <span className="s-name-value">
                      {studentDetails?.primaryContactNumber}
                    </span>
                  </div>
                  <div className="student-details-row">
                    <span className="s-name-key">Primary Contact Email:</span>
                    <span className="s-name-value">
                      {studentDetails?.primaryContactEmail}
                    </span>
                  </div>
                </div>
                <div className="svg-icon-secion-sd">
                  <span onClick={handleEditStudent}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      width="32px"
                      height="32px"
                    >
                      <path d="M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z" />
                    </svg>
                  </span>
                </div>
              </div>
              <div>
                <div className="student-fee-details">
                  <div className="student-type-sec">
                    <span className="amount-type">Total Amount: </span>
                    <span className="amount-value">₹{getTotalAmount()}.00</span>
                  </div>
                  <div className="student-type-sec">
                    <span className="amount-type">Paid Amount: </span>
                    <span className="amount-value">₹{getPaidAmount()}.00</span>
                  </div>
                  <div className="student-type-sec">
                    <span className="amount-type">Unpaid Amount: </span>
                    <span className="amount-value">
                      ₹{getTotalAmount() - getPaidAmount()}.00
                    </span>
                  </div>
                </div>
              </div>
              <div className="student-full-structure">
                <div className="student-full-structure-header">
                  <h3>{studentDetails?.studentName} Fee Details</h3>
                </div>
                <div className="student-full-structure-body">
                  <Tabs
                    defaultActiveKey="1"
                    items={items1}
                    onChange={onTabChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          show={showSuccessMessage}
          titleText={"Student Details Successfully Updated."}
          deleteText="Ok"
          cancelText=""
          onDelete={() => {
            setShowSuccessMessage(false);
            // setIsProfileEdit(false);
            // history.push("/students");
          }}
        />
        <EditStudentDetails
          show={editStudentModel}
          selectedStudentId={studentDetails?._id}
          handleClose={handleEditClose}
          handleCloseSuccess={handleCloseSuccess}
        />
        <DropOffStudent
          show={showDropOffStudent}
          selectedStudentId={studentDetails?._id}
          handleEditClose={handleEditClose}
        />
        <CreatePaymentLink
          show={showCreatePaymentLink}
          selectedStudentId={studentDetails?._id}
          handleEditClose={handleEditClose}
          handleClose={handleEditClose}
        />

        <SendRegistrationLink
          show={showSendRegistrationLink}
          selectedStudentId={studentDetails?._id}
          handleEditClose={handleEditClose}
          handleClose={handleEditClose}
        />
      </StudentDetailsStyled>
    </>
  );
};

export default StudentDetails;
