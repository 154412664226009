import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { getAllStudents } from "./../../redux/slices/StudentManagement/StudentManagementService";
import { triggerMixpanelEvent } from "../../Scenes/common";
import { updatesStudentDetails } from "./../../redux/slices/StudentManagement/StudentManagementSlice";
import { acadamicYearsList, classesList, admissionList } from "./../../config";
import Loader from "./../../components/Common/Loader";
import CustomModal from "./../../components/CustomModel";

import EditStudentDetails from "./StudentDetails/EditStudentDetails";
import DropOffStudent from "./DropOffStudent";
import CreatePaymentLink from "./CreatePaymentLink/CreatePaymentLink";
import SendRegistrationLink from "./SendRegistrationLink/SendRegistrationLink";
import CreateApplication from "../../components/CreateApplication/CreateApplication";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import upload_icon from "../../img/delta/backup.png";
import add_icon from "../../img/delta/add.png";
import CreateStudentModel from "./CreateStudentModel/CreateStudentModel";
const Students = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [createStudentModelShow, setCreateStudentModelShow] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showDropOffStudent, setShowDropOffStudent] = useState(false);
  const [showSendRegistrationLink, setShowSendRegistrationLink] =
    useState(false);
  const [showCreatePaymentLink, setShowCreatePaymentLink] = useState(false);
  const [showEditStudentModel, setShowEditStudentModel] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [newAdmission, setNewAdmission] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState({} as any);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isAdminSite } = useSelector(
    (state: any) => state?.auth
  );
  const { allStudents } = useSelector((state: any) => state?.studentManagement);
  console.log("allStudents", allStudents);

  useEffect(() => {
    triggerMixpanelEvent("Students_page");
    const body = { instituteId: user?._id };
    getAllStudentsCall();
  }, []);
  useEffect(() => {
    getAllStudentsCall();
  }, [signUpData, currentPage]);
  const getAllStudentsCall = async () => {
    console.log("signUpData", signUpData);
    console.log("studentGrade", studentGrade);
    console.log("academicYear", academicYear);
    let newJson = {} as any;
    // signUpData.map((itt: any) => {
    //   console.log("itt", itt);
    // });
    for (const [key, value] of Object.entries(signUpData)) {
      if (value && value !== "All") {
        newJson[key] = value;
      }
      console.log(`${key}: ${value}`);
    }
    let body = { instituteId: user?._id, ...newJson };
    if (isAdminSite) {
      body = { ...newJson };
    }
    body.page = currentPage;
    setIsLoading(true);
    await dispatch(getAllStudents(body));
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCreatenewApp = () => {
    dispatch(updatesStudentDetails({}));
    // history.push("/createStudent");
    setCreateStudentModelShow(true);
  };
  const handleBulkUpload = () => {
    history.push("/students/upload");
  };

  const StudentAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "edit") {
      setSelectedStudentId(id);
      setCreateStudentModelShow(true);
      // history.push(`/createStudent/${id}`);
    } else if (actionN === "DropOff") {
      handleOpenDropOff();
    } else if (actionN === "PaymentLink") {
      setShowCreatePaymentLink(true);
    } else if (actionN === "RegistrationLink") {
      setShowSendRegistrationLink(true);
    }
  };

  const handleEditClose = () => {
    setCreateStudentModelShow(false);
    setShowDropOffStudent(false);
    setShowCreatePaymentLink(false);
    setShowSendRegistrationLink(false);
  };
  const handleOpenDropOff = () => {
    setShowDropOffStudent(true);
  };
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["studentGrade"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentGrade(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeAdmission = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["newAdmission"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setNewAdmission(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const studentAddSuccess = () => {
    setCreateStudentModelShow(false);
    setShowSuccessMessage(true);
    dispatch(getAllStudents({ instituteId: user?._id }));
  };

  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "view",
      label: <span className="drop-down-single-item-sec">View</span>,
    },

    {
      key: "DropOff",
      label: (
        <span className="drop-down-single-item-sec">Mark as Drop Off</span>
      ),
    },

    {
      key: "PaymentLink",
      label: (
        <span className="drop-down-single-item-sec">Create Payment Link</span>
      ),
    },

    {
      key: "RegistrationLink",
      label: (
        <span className="drop-down-single-item-sec">Send auto pay link</span>
      ),
    },

    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Name",
      width: 200,
      key: "0",
      fixed: "left",
      render: (item: any) => {
        return (
          <>
            <span
              onClick={() => {
                history.push(`/students/${item?._id}`);
              }}
              className="table-student-name"
            >
              {item?.studentName}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Student Grade",
      dataIndex: "studentGrade",
      key: "2",
      width: 200,
    },
    {
      title: "Mobile Number",
      dataIndex: "primaryContactNumber",
      key: "2",
      width: 200,
    },

    {
      title: "Roll Number",
      dataIndex: "rollNumber",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Academic Year",
      dataIndex: "academicYear",
      key: "42",
      width: 200,
    },
    {
      title: "Admission Type",
      dataIndex: "newAdmission",
      key: "4",
      width: 200,
    },
    {
      title: "Total (₹)",
      dataIndex: "panNo",
      key: "2",
      width: 200,
    },

    {
      title: "Amount Paid (₹)",
      dataIndex: "establishedYear",
      key: "5",
      width: 150,
    },

    {
      title: "Pending Paid (₹)",
      dataIndex: "establishedYear",
      key: "5",
      width: 150,
    },

    {
      title: "Products",
      dataIndex: "establishedYear",
      key: "5",
      width: 150,
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  StudentAction(itemId, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const admissionList = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Yes",
      value: 133,
    },
    {
      label: "No",
      value: 1403,
    },
  ] as any;

  return (
    <>
      <StudentsStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          {/* <CreateStudentModel
            show={showEditStudentModel}
            selectedStudentId={selectedStudentId}
            handleEditClose={handleEditClose}
          /> */}
          <CreateStudentModel
            show={createStudentModelShow}
            selectedStudentId={selectedStudentId}
            handleCloseSuccess={studentAddSuccess}
            handleClose={() => {
              setCreateStudentModelShow(false);
            }}
          />
          <CustomModal
            show={showSuccessMessage}
            titleText={
              selectedStudentId
                ? "Student Successfully Updated."
                : "Student Successfully Created."
            }
            deleteText="Ok"
            cancelText=""
            onDelete={() => {
              setShowSuccessMessage(false);
            }}
          />
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Students" }]} />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Manage Students</span>

                  <div>
                    <span className="profileEdit" onClick={handleBulkUpload}>
                      Bulk Upload &nbsp;
                      <img src={upload_icon} />
                    </span>
                    <span className="profileEdit" onClick={handleCreatenewApp}>
                      Create New Student &nbsp; <img src={add_icon} />
                    </span>
                  </div>
                </div>
                <p>View All Students</p>
              </div>
            </div>

            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Student Name</Form.Label>
                    <Form.Control
                      placeholder="Search: Name, Roll Number, Email, Phone"
                      // autoFocus
                      // isInvalid={errorData?.studentName}
                      name="studentName"
                      type="text"
                      value={signUpData?.studentName}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div>
                <div className="delta-select-column">
                  <label>
                    Grade <span className="mand-sign-field">*</span>
                  </label>
                  <Select
                    // value={studentGrade ?? "All"}
                    value={{
                      label: studentGrade?.label ?? "All",
                      value: studentGrade?.label ?? "All",
                    }}
                    placeholder="Select Grade"
                    onChange={handleChangeGrade}
                    options={[
                      {
                        label: "All",
                        value: "",
                      },
                      ...classesList,
                    ]}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="delta-select-column">
                  <label>
                    Academic Year <span className="mand-sign-field">*</span>{" "}
                  </label>
                  <Select
                    // value={academicYear?.label ?? "All"}
                    value={{
                      label: academicYear?.label ?? "All",
                      value: academicYear?.label ?? "All",
                    }}
                    placeholder="Select Year"
                    onChange={handleChangeYear}
                    options={acadamicYearsList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                {/* <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label>Roll Number</Form.Label>
                    <Form.Control
                      // autoFocus
                      name="rollNumber"
                      type="text"
                      value={signUpData?.rollNumber}
                      onChange={(e) => handleChangeValue(e)}
                    />
                  </Form.Group>
                </div> */}
                <div className="delta-select-column">
                  <label>
                    New Admission <span className="mand-sign-field">*</span>{" "}
                  </label>
                  <Select
                    value={{
                      label: newAdmission?.label ?? "All",
                      value: newAdmission?.label ?? "All",
                    }}
                    placeholder="Select Admission"
                    onChange={handleChangeAdmission}
                    options={admissionList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button onClick={handleSubmit} className="deltape-button">
                    SUBMIT
                  </button>
                </div> */}
              </div>
            </div>
            <br />
            <div>
              <div className="total-count-row">
                <span>
                  Showing {allStudents?.data?.length} of{" "}
                  {allStudents?.totalRecords} Students
                </span>
              </div>
              <div className="Students-filters"></div>
              <div className="all-Students-data deltape-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={allStudents?.data}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }

                  // total={50}
                />
                {/* <Pagination
                  defaultCurrent={1}
                  total={allStudents?.totalRecords}
                /> */}
                {/* noOfPages */}
                <br />
                <div className="delata-table-pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    total={allStudents?.totalRecords}
                    onChange={(val) => {
                      pageonChange(val);
                    }}
                    pageSize={10}
                    showSizeChanger={false}
                    // size="small"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* {allStudents?.length === 0 && (
            <div className="no-results">
              <p>No Students available</p>
            </div>
          )} */}

          <DropOffStudent
            show={showDropOffStudent}
            selectedStudentId={selectedStudentId}
            handleEditClose={handleEditClose}
          />
          <CreatePaymentLink
            show={showCreatePaymentLink}
            selectedStudentId={selectedStudentId}
            handleEditClose={handleEditClose}
            handleClose={handleEditClose}
          />

          <SendRegistrationLink
            show={showSendRegistrationLink}
            selectedStudentId={selectedStudentId}
            handleEditClose={handleEditClose}
            handleClose={handleEditClose}
          />
        </div>
      </StudentsStyled>
    </>
  );
};

export default Students;
