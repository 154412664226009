import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";
import self_emp from "../../../img/delta/self_emp.png";
import salaried_img from "../../../img/delta/salaried_img.png";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import step1 from "../../../img/delta/image_step1.png";
import user_info_icon from "../../../img/delta/user_info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  storeName: undefined,
  storeEmail: undefined,
  storeMobileNo: undefined,
  storeWebsite: undefined,
  storeGSTNumber: undefined,
  studentId: undefined,
  feeAmount: undefined,
};
const initValError = {
  storeName: undefined,
  studentId: undefined,
  feeAmount: undefined,
};
const errorDataValue = {
  pancardFirstName: "Please Enter First name",
  pancardLastName: "Please Enter Last name",
  mobileNo: "Please Enter Phone number",
  studentId: "Please enter Student ID",
  feeAmount: "Please enter Fee Amount",
  aadhaarNo: "Please enter Aadhaar No",
  pancard: "Please enter Pan card",
};
const UserInformation = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [empType, setEmpType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  const allBanksList = [
    {
      label: "Axis Bank",
      value: "Axis Bank",
      url: "https://retail.axisbank.co.in",
    },
    {
      label: "Bandhan Bank",
      value: "Bandhan Bank",
      url: "https://bandhanbankonline.com",
    },
    {
      label: "Bank of Baroda",
      value: "Bank of Baroda",
      url: "https://www.bobibanking.com",
    },
    {
      label: "Bank of India",
      value: "Bank of India",
      url: "https://www.bankofindia.co.in",
    },
    {
      label: "Bank of Maharashtra",
      value: "Bank of Maharashtra",
      url: "https://www.mahaconnect.in",
    },
    {
      label: "Canara Bank",
      value: "Canara Bank",
      url: "https://netbanking.canarabank.in",
    },
    {
      label: "Central Bank of India",
      value: "Central Bank of India",
      url: "https://www.centralbank.net.in",
    },
    {
      label: "City Union Bank",
      value: "City Union Bank",
      url: "https://inetbanking.cityunionbank.com",
    },
    { label: "CSB Bank", value: "CSB Bank", url: "https://www.csbnet.co.in" },
    { label: "DCB Bank", value: "DCB Bank", url: "https://www.dcbbank.com" },
    {
      label: "Dhanlaxmi Bank",
      value: "Dhanlaxmi Bank",
      url: "https://www.dhanbankonline.com",
    },
    {
      label: "Federal Bank",
      value: "Federal Bank",
      url: "https://www.fednetbank.com",
    },
    {
      label: "HDFC Bank",
      value: "HDFC Bank",
      url: "https://netbanking.hdfcbank.com",
    },
    {
      label: "ICICI Bank",
      value: "ICICI Bank",
      url: "https://www.icicibank.com",
    },
    { label: "IDBI Bank", value: "IDBI Bank", url: "https://www.idbibank.in" },
    {
      label: "IDFC FIRST Bank",
      value: "IDFC FIRST Bank",
      url: "https://www.idfcfirstbank.com",
    },
    {
      label: "Indian Bank",
      value: "Indian Bank",
      url: "https://www.indianbank.net.in",
    },
    {
      label: "Indian Overseas Bank",
      value: "Indian Overseas Bank",
      url: "https://www.iobnet.co.in",
    },
    {
      label: "IndusInd Bank",
      value: "IndusInd Bank",
      url: "https://www.indusind.com",
    },
    {
      label: "Jammu & Kashmir Bank",
      value: "Jammu & Kashmir Bank",
      url: "https://www.jkbank.com",
    },
    {
      label: "Karnataka Bank",
      value: "Karnataka Bank",
      url: "https://kblnet.karnatakabank.com",
    },
    {
      label: "Karur Vysya Bank",
      value: "Karur Vysya Bank",
      url: "https://www.kvb.co.in",
    },
    {
      label: "Kotak Mahindra Bank",
      value: "Kotak Mahindra Bank",
      url: "https://www.kotak.com",
    },
    {
      label: "Nainital Bank",
      value: "Nainital Bank",
      url: "https://www.nainitalbank.co.in",
    },
    {
      label: "Punjab & Sind Bank",
      value: "Punjab & Sind Bank",
      url: "https://www.psbindia.com",
    },
    {
      label: "Punjab National Bank",
      value: "Punjab National Bank",
      url: "https://www.netpnb.com",
    },
    {
      label: "RBL Bank (Ratnakar Bank)",
      value: "RBL Bank (Ratnakar Bank)",
      url: "https://www.rblbank.com",
    },
    {
      label: "South Indian Bank",
      value: "South Indian Bank",
      url: "https://www.southindianbank.com",
    },
    {
      label: "State Bank of India",
      value: "State Bank of India",
      url: "https://www.onlinesbi.com",
    },
    {
      label: "Tamilnad Mercantile Bank",
      value: "Tamilnad Mercantile Bank",
      url: "https://www.tmbnet.in",
    },
    {
      label: "UCO Bank",
      value: "UCO Bank",
      url: "https://www.ucoebanking.com",
    },
    {
      label: "Union Bank of India",
      value: "Union Bank of India",
      url: "https://www.unionbankonline.co.in",
    },
    { label: "YES Bank", value: "YES Bank", url: "https://www.yesbank.in" },
  ];
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setSignUpData({
        ...addr,
        studentId: applicationDetails?.studentId,
        feeAmount: applicationDetails?.feeAmount,
      });
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    setInstituteErrorMessage("");
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setCurrentStep("4");
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
          // history.push("/customerDashboard");
        } else {
          setInstituteErrorMessage("Please Enter Valid Pan Card.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
    console.log("allInstitutesList", allInstitutesList);
  };
  const selectEmptype = (val: any) => {
    setEmpType(val);
    setErrorMessage("");
    console.log("valval", val);
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  const handleProceed = () => {
    if (!instituteValue?.value) {
      setErrorMessage("Please select Bank Name");
    } else {
      window.open(instituteValue?.url, "_blank");
    }
  };
  return (
    <>
      <div className="create-new-application-sec income-verification-sec">
        {isLoading && <Loader />}
        <>
          <div>
            <div className="user_info_icon_sec_top">
              <img src={user_info_icon} />
              <p>Income Verification</p>
              <div className="d-flex1">
                <div>
                  <img src={self_emp} />
                </div>
                <p>
                  To streamline your approval process, we will require your bank
                  accountt details where you receive your salary
                </p>
              </div>
            </div>
            <div className="income-verification-con-sec">
              <div className="delta-select-row">
                <label>Choose your Bank </label>
                <Select
                  value={
                    instituteValue?.value ? instituteValue : "Choose your Bank"
                  }
                  onChange={handleChange}
                  options={allBanksList}
                  placeholder="Choose your Bank"
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
              </div>
              <div className="all-pan-address-row-all">
                <div
                  onClick={() => {
                    selectEmptype("salaried");
                  }}
                >
                  <div className="d-flex">
                    <div>
                      <img src={self_emp} />
                    </div>
                    <div>
                      <h6>Net Banking</h6>
                      <p>Login to Net banking on you bank portal</p>
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="Rented22"
                      name="select_address_user"
                      value="salaried"
                    />
                  </div>
                </div>
                <div
                  onClick={() => {
                    selectEmptype("self");
                  }}
                >
                  <div className="d-flex">
                    <div>
                      <img src={salaried_img} />{" "}
                    </div>
                    <div>
                      <h6>Upload Bank Statement</h6>
                      <p>Upload PDF of last 6 months bank statement</p>
                    </div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="Rented22"
                      name="select_address_user"
                      value="self"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && (
            <p className="error_message text_left">{errorMessage}</p>
          )}
        </>

        <div className="actions-btn-sec">
          <div className="deltape-action-btns">
            <button onClick={props?.prevStep} className="cancel-btn">
              Back
            </button>
            <button onClick={handleProceed} className="confirm-btn">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInformation;
