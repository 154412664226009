import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { StudentModulesStyled } from "./StudentModules.styled";

import Select from "react-select";
import { getStudentDetailsAPI } from "../../../redux/slices/StudentManagement/StudentManagementService";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../../components/Common/Loader";
import CustomModal from "../../../components/CustomModel";
import EditStudentDetails from "../StudentDetails/EditStudentDetails";
// import ProductCard from "./../../components/ProductCard";
import { Tabs } from "antd";
import type { TabsProps } from "antd";

import moment from "moment";
import { triggerMixpanelEvent } from "./../../../Scenes/common";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

const FeeStructure = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [editStudentModel, setEditStudentModel] = useState(false);

  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { studentDetails } = useSelector(
    (state: any) => state?.studentManagement
  );

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleCancel = () => {
    history.push("/students");
  };
  const handleEdit = (id: any) => {
    setCurrentStep(`createStudent/${id}`);
  };
  const handleEditStudent = () => {
    setEditStudentModel(true);
  };
  const handleEditClose = () => {
    setEditStudentModel(false);
  };
  const onTabChange = (key: string) => {
    console.log(key);
  };
  return (
    <>
      <StudentModulesStyled>
        <div className="create-new-institute-sec">
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="add-paymet-btn">
                <button className="deltape-button">
                  Add New Fee Structure
                </button>
              </div>
            </div>
          </div>
        </div>
      </StudentModulesStyled>
    </>
  );
};

export default FeeStructure;
