import { configureStore } from "@reduxjs/toolkit";

// reducers
import appReducer from "../slices/app/appSlice";
import authReducer from "../slices/auth/authSlice";
// import serviceManagementReducer from "../slices/serviceManagement/serviceManagementSlice";
// import leadManagementReducer from "../slices/LeadManagement/leadManagementSlice";
// import bookingManagementReducer from "../slices/BookingManagement/BookingManagementSlice";
// import storeManagementReducer from "../slices/storeManagement/storeManagementSlice";
import reportsReducer from "../slices/Reports/ReportsSlice";
import ordersReducer from "../slices/orders/ordersSlice";
import PlansandPaymentsReducer from "../slices/PlansandPayments/PlansandPaymentsSlice";
import InstituteManagementReducer from "../slices/InstituteManagement/InstituteManagementSlice";
import StudentManagementReducer from "../slices/StudentManagement/StudentManagementSlice";
import UserManagementReducer from "../slices/UserManagement/UserManagementSlice";
import ClientsReducer from "../slices/Clients/ClientsSlice";
import AssignmentsReducer from "../slices/Assignments/AssignmentsSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    // serviceManagement: serviceManagementReducer,
    // leadManagement: leadManagementReducer,
    // bookingManagement: bookingManagementReducer,
    // storeManagement: storeManagementReducer,
    orders: ordersReducer,
    reports: reportsReducer,
    plansandPayments: PlansandPaymentsReducer,
    instituteManagement: InstituteManagementReducer,
    studentManagement: StudentManagementReducer,
    userManagement: UserManagementReducer,
    clients: ClientsReducer,
    assignments: AssignmentsReducer,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
