import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createClientAPI,
  getAllClients,
  getClientDetailsAPI,
} from "./AssignmentsService";

export interface leadManagementState {
  loading: boolean;
  error: any;
  clientDetails: any;
  allClients: any;
}

const initialState: leadManagementState = {
  loading: false,
  error: null,
  clientDetails: {},
  allClients: {},
};

export const ClientManagement = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: leadManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: leadManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
    updatesClientDetails: (
      state: leadManagementState,
      action: PayloadAction<any>
    ) => {
      state.clientDetails = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(createClientAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      createClientAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.clientDetails = action.payload?.data;
      }
    );
    builder.addCase(
      createClientAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.clientDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(getClientDetailsAPI.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getClientDetailsAPI.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.clientDetails = action.payload?.data;
      }
    );
    builder.addCase(
      getClientDetailsAPI.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.clientDetails = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );

    //getAllClients
    builder.addCase(getAllClients.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllClients.fulfilled,
      (state: leadManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.allClients = action.payload;
      }
    );
    builder.addCase(
      getAllClients.rejected,
      (state: leadManagementState, action: any) => {
        state.loading = false;
        state.allClients = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
  },
});

export const { setLoading, updateErrorMessage, updatesClientDetails } =
  ClientManagement.actions;

export default ClientManagement.reducer;
