import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

// router.route("/create").post(Client.createClient);
// router.route("/all").post(Client.getAllClients);
// router.route("/:clientId").get(Client.GetClientById);
// router.route("/:clientId").delete(Client.DeleteClientById);
// router.route("/update").put(Client.updateClient);

export const createClientAPI = createAsyncThunk(
  "reports/createClientAPI",
  async (body: any) => await post(`${SERVER_IP}/assignment/create`, body)
);
export const getClientDetailsAPI = createAsyncThunk(
  "reports/getClientDetailsAPI",
  async (iID: any) => await get(`${SERVER_IP}/assignment/${iID}`)
);

export const updateClientDetailsAPI = createAsyncThunk(
  "reports/updateClientDetailsAPI",
  async (body: any) => await put(`${SERVER_IP}/assignment/update`, body)
);

export const getAllClients = createAsyncThunk(
  "reports/getAllClients",
  async (body: any) =>
    await post(`${SERVER_IP}/assignment/all?page=${body?.page || 10}`, body)
);
