import React, { useEffect, useState } from "react";
import { DeltaAutoStyled } from "./DeltaAuto.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../Scenes/common";
import Payments from "../Students/StudentModules/Payments";
import {
  getStudentDetailsAPI,
  getAllFeeComponentsAPI,
  getAllDirectPaymentsAPI,
} from "../../redux/slices/StudentManagement/StudentManagementService";
import Select from "react-select";
import { Tabs } from "antd";

const Overdue = (props: any) => {
  const { section } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpData, setSignUpData] = useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);

  return (
    <>
      <div>{section} Section</div>
    </>
  );
};

export default Overdue;
