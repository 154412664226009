import React, { useEffect, useState } from "react";
import { CustomerDashboardStyled } from "./CustomerDashboard.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import info_img from "../../img/delta/info.png";
import edit_img from "../../img/delta/edit.png";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import add_icon from "../../img/delta/add.png";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../redux/slices/PlansandPayments/PlansandPaymentsService";
import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../..//redux/slices/InstituteManagement/InstituteManagementService";

import { updateApplicationDetails } from "../../redux/slices/PlansandPayments/PlansandPaymentsSlice";
import NewChildComponent from "./NewChildComponent";
import { triggerMixpanelEvent } from "./../../Scenes/common";
const CustomerDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [showAddNewChild, setShowAddNewChild] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [applicaId, setApplicaId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((authR) => authR?.auth);
  const { userAllApplications } = useSelector(
    (authR) => authR?.plansandPayments
  );
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    dispatch(getAllInstitutes());
    dispatch(getAllApplicationsbyuser(user?._id));
  }, []);

  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleEdit = (itm) => {
    setApplicaId(itm?._id);
    setShowAddNewChild(true);
  };
  const handleAddNewChild = async () => {
    setApplicaId("");
    dispatch(updateApplicationDetails({}));
    setShowAddNewChild(true);
  };
  const handleClose = async () => {
    setShowAddNewChild(false);
  };

  return (
    <>
      <CustomerDashboardStyled>
        <div className="freshbag-wrapper">
          <NewChildComponent
            applicationId={applicaId}
            show={showAddNewChild}
            handleClose={handleClose}
          />
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Dashboard" }]} />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Dashboard</span>
                  <div>
                    <button
                      className="deltape-button"
                      onClick={handleAddNewChild}
                    >
                      Add New Child &nbsp; <img src={add_icon} />
                    </button>
                  </div>
                </div>
                {/* <p>View All Users</p> */}
              </div>
            </div>
            <div className="contact">
              <div className="row">
                <div className="all-applocations-list">
                  {userAllApplications?.map((item) => {
                    return (
                      <div className="application-box">
                        <div className="prev-values-section1">
                          <div className="app-preview-row">
                            <div className="app-preview-top">
                              <div>Student Details</div>
                              <div>
                                <img
                                  onClick={() => handleEdit(item)}
                                  className="edit-img"
                                  src={edit_img}
                                />
                                <button
                                  className="deltape-button"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                >
                                  View Plans
                                </button>
                              </div>
                            </div>
                            <div className="app-preview-text">
                              <div className="all-fields-rows">
                                <div className="all-fields-row">
                                  <p className="key">
                                    School / College / University Name
                                  </p>
                                  <p className="value">{item?.instituteName}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Location</p>
                                  <p className="value">{item?.locationId}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Academic Year</p>
                                  <p className="value">{item?.academicYear}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Board/Class/Stream</p>
                                  <p className="value">{item?.classId}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Student First Name</p>
                                  <p className="value">
                                    {item?.studentFirstName}
                                  </p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Student Last Name</p>
                                  <p className="value">
                                    {item?.studentLastName}
                                  </p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Admission Type</p>
                                  <p className="value">{item?.admissionType}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Student ID </p>
                                  <p className="value">{item?.studentId}</p>
                                </div>
                                <div className="all-fields-row">
                                  <p className="key">Annual Fee</p>
                                  <p className="value">Rs. {item?.feeAmount}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomerDashboardStyled>
    </>
  );
};

export default CustomerDashboard;
