import React, { useEffect, useState } from "react";
import { ReportsStyled } from "./Reports.styled";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";

import { Row, Col, Button, Form, CardDeck, CardColumns } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { getAllInstitutes } from "../../redux/slices/InstituteManagement/InstituteManagementService";
import { triggerMixpanelEvent } from "../../Scenes/common";
import Select from "react-select";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
import { DatePicker } from "antd";
import no_reports_img from "../../img/delta/Frame 1707482311.png";
import CreateApplication from "../../components/CreateApplication/CreateApplication";

const { RangePicker } = DatePicker;

const Reports = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [thankyouMessage, setThankyouMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [showCreateAppli, setShowCreateAppli] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [signUpData, setSignUpData] = useState({} as any);
  const [sDate, setSDate] = useState(null as any);
  const [eDate, setEDate] = useState(null as any);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);
  const { allInstitutes } = useSelector(
    (state: any) => state?.instituteManagement
  );
  console.log("allInstitutes", allInstitutes);

  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    dispatch(getAllInstitutes());
  }, []);
  const handleChangeValue = (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const handleChangeRole = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["reportType"]: selectedOption?.label,
    });
  };
  const handleChangeStatus = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["status"]: selectedOption?.label,
    });
  };
  const handleDateChange = (seDate: any) => {
    const sDate = moment(seDate[0]).format("YYYY-MM-DD");
    const eDate = moment(seDate[1]).format("YYYY-MM-DD");
    setSDate(sDate);
    setEDate(eDate);
    const body = {
      stateDate: sDate,
      endDate: eDate,
    };
  };
  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleCreatenewApp = () => {
    history.push("/institutesetup");
  };
  const instituteAction = (id: any, actionN: any) => {
    console.log("idid", id);
    if (actionN === "edit") {
      history.push(`/institutesetup/${id}`);
    }
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const items = [
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];
  const columns = [
    {
      title: "Name",
      width: 200,
      dataIndex: "instituteName",
      key: "0",
      fixed: "left",
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },

    {
      title: "Mobile Number",
      dataIndex: "instituteCode",
      key: "2",
      width: 200,
    },

    {
      title: "Email",
      dataIndex: "instituteLocation",
      key: "3",
      width: 200,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Institute",
      dataIndex: "phoneNumber",
      key: "42",
      width: 200,
    },
    {
      title: "Role",
      dataIndex: "email",
      key: "4",
      width: 200,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "7",
      width: 150,
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 90,
      dataIndex: "_id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  instituteAction(itemId, key);
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const rolesList = [
    {
      label: "Fee Master MIS",
      value: "Fee Master MIS",
    },
    {
      label: "Payments MIS",
      value: "Payments MIS",
    },
    {
      label: "Flex Dashboard MIS",
      value: "Flex Dashboard MIS",
    },
    {
      label: "Cred Dashboard MIS",
      value: "Cred Dashboard MIS",
    },
    {
      label: "Flex Summary",
      value: "Flex Summary",
    },
    {
      label: "Payment Page",
      value: "Payment Page",
    },
  ] as any;
  const rolesList1 = [
    {
      label: "In Queue",
      value: "In Queue",
    },
    {
      label: "Processing",
      value: "Processing",
    },
    {
      label: "Success",
      value: "Success",
    },
    {
      label: "Failed",
      value: "Failed",
    },
  ] as any;
  return (
    <>
      <ReportsStyled>
        <div className="freshbag-wrapper">
          <div className="content getinTouchPage">
            <div>
              <BreadCrumb list={[{ title: "Reports" }]} />
              <div className="profileinfoHeader">
                <div>
                  <span className="edit-p-text">Manage Reports</span>
                </div>
                <p>View All Reports</p>
              </div>
            </div>
            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Date Range</Form.Label>

                    <RangePicker onChange={handleDateChange} />
                  </Form.Group>
                </div>

                <div className="delta-select-column">
                  <label>Report Type </label>
                  <Select
                    value={{
                      label: signUpData?.reportType ?? "All",
                      value: signUpData?.reportType ?? "All",
                    }}
                    placeholder="Select Report Type"
                    onChange={handleChangeRole}
                    options={rolesList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="delta-select-column">
                  <label>Status </label>
                  <Select
                    value={{
                      label: signUpData?.status ?? "All",
                      value: signUpData?.status ?? "All",
                    }}
                    placeholder="Select Status"
                    onChange={handleChangeStatus}
                    options={rolesList1}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div></div>
              </div>
            </div>
            <div>
              <div className="institutes-filters"></div>
              {/* <div className="all-institutes-data deltape-table-view">
                <Table
                  columns={columns}
                  pagination={false}
                  dataSource={allInstitutes}
                  scroll={{ x: 1500, y: 500 }}
                  rowClassName={(record, index) =>
                    `row_selected_priority_${record?.priority}`
                  }

                  // total={50}
                />

                <div className="delata-table-pagination">
                  <Pagination
                    defaultCurrent={1}
                    current={currentPage}
                    total={allInstitutes?.length}
                    onChange={(val) => {
                      pageonChange(val);
                    }}
                    pageSize={10}
                    showSizeChanger={false}
                    // size="small"
                  />
                </div>
              </div> */}
            </div>
          </div>
          {/* {allInstitutes?.length === 0 && ( */}
          <div className="no-reports-count">
            <div className="no-results">
              {/* <p>No Reports available</p> */}
              <img src={no_reports_img} />
            </div>
          </div>
          {/* )} */}
        </div>
      </ReportsStyled>
    </>
  );
};

export default Reports;
