import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import step1 from "../../../img/delta/image_step1.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  studentId: undefined,
  feeAmount: undefined,
  studentFirstName: undefined,
  studentLastName: undefined,
  admissionType: undefined,
};
const initValError = {
  studentFirstName: undefined,
  studentLastName: undefined,
  studentId: undefined,
  feeAmount: undefined,
  admissionType: undefined,
};
const errorDataValue = {
  studentFirstName: "Please Enter First name",
  studentLastName: "Please Enter Last name",
  admissionType: "Please Select Admission Type",
  studentId: "Please enter Student ID",
  feeAmount: "Please enter Fee Amount",
  instituteId: "Please Select Institute",
  locationId: "Please Select Location",
  academicYear: "Please Select Academic Year",
  classId: "Please Select Class",
};
const SchoolSelection = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  let showAllFields = false;
  if (
    instituteValue?.value &&
    locationValue?.value &&
    academicYear?.value &&
    academicClass?.value
  ) {
    showAllFields = true;
  }

  useEffect(() => {
    dispatch(getAllInstitutes());
    dispatch(getAllApplicationsbyuser(user?._id));

    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setInstituteValue({
        label: applicationDetails?.instituteName,
        value: applicationDetails?.instituteId,
      });
      setLocationValue({
        label: applicationDetails?.locationId,
        value: applicationDetails?.locationId,
      });
      setAcademicClass({
        label: applicationDetails?.classId,
        value: applicationDetails?.classId,
      });
      setAcademicYear({
        label: applicationDetails?.academicYear,
        value: applicationDetails?.academicYear,
      });

      setSignUpData({
        ...addr,
        instituteId: instituteValue?.value,
        instituteName: instituteValue?.label,
        locationId: locationValue?.value,
        academicYear: academicYear?.value,
        classId: academicClass?.value,
        studentId: applicationDetails?.studentId,
        feeAmount: applicationDetails?.feeAmount,
        studentFirstName: applicationDetails?.studentFirstName,
        studentLastName: applicationDetails?.studentLastName,
        admissionType: applicationDetails?.admissionType,
      });
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);
  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    if (
      !instituteValue?.value ||
      !locationValue?.value ||
      !academicYear?.value ||
      !academicClass?.value
    ) {
      isValid = false;
    }
    setErrorData({ ...errornew });
    return isValid;
  };
  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setCurrentStep("4");
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  console.log("applicationId", applicationId);
  const handleProceed = async () => {
    if (handleValidate()) {
      let body = {
        instituteId: instituteValue?.value,
        instituteName: instituteValue?.label,
        locationId: locationValue?.value,
        academicYear: academicYear?.value,
        classId: academicClass?.value,
        studentId: signUpData?.studentId,
        feeAmount: signUpData?.feeAmount,
        studentFirstName: signUpData?.studentFirstName,
        studentLastName: signUpData?.studentLastName,
        admissionType: signUpData?.admissionType,
        userId: user?._id,
      } as any;
      console.log("body", body);
      setIsLoading(true);
      if (applicationId) {
        body._id = applicationId;
        const resp = (await dispatch(updateApplicationAPI(body))) as any;
        if (resp?.payload?.success) {
          props?.createApplicationSuccess(applicationId);
        } else {
          setErrorMessage("Please enter valid details!");
        }
      } else {
        const resp = (await dispatch(createApplicationAPI(body))) as any;
        if (resp?.payload?.application?._id) {
          props?.createApplicationSuccess(resp?.payload?.application?._id);
        } else {
          setErrorMessage("Please enter valid details!");
        }
      }
      setIsLoading(false);
    } else {
      setShowErrorMessage(true);
    }

    // props?.nextStep();
  };
  console.log("allInstitutesList", allInstitutesList);
  return (
    <>
      <div className="create-new-application-sec">
        {isLoading && <Loader />}

        <>
          <div className="step1-header-sec-main-img">
            <div>
              <img src={step1} />
            </div>
            <div className="">
              <h5>
                Explore Best <span>EMI Plans</span>
              </h5>
              <p>
                Enter Student Details to discover the perfect EMI plan for you.
              </p>
            </div>
          </div>
          <div className="actions-top-content">
            <div className="delta-select-row">
              <label>School/College/University Name </label>
              <Select
                value={instituteValue?.value ? instituteValue : "Select School"}
                onChange={handleChange}
                options={allInstitutesList}
                placeholder="Select School"
                className="delta-select"
                classNamePrefix="delta-select"
              />
              <Form.Control.Feedback type="invalid">
                {!instituteValue?.value && showErrorMessage ? (
                  <>{errorDataValue?.classId}</>
                ) : null}
              </Form.Control.Feedback>
            </div>
            <div className="delta-select-row">
              <label>Select Location </label>
              <Select
                value={locationValue?.value ? locationValue : "Select Location"}
                onChange={handleChangeLocation}
                options={allLocationsList}
                className="delta-select"
                placeholder="Select Location"
                classNamePrefix="delta-select"
                isDisabled={!instituteValue?.value}
              />
              <Form.Control.Feedback type="invalid">
                {!locationValue?.value && showErrorMessage ? (
                  <>{errorDataValue?.classId}</>
                ) : null}
              </Form.Control.Feedback>
            </div>
            {/* <div className="delta-select-row">
                  <label>Select Board / Class / Stream </label>
                  <Select
                    value={boardValue}
                    onChange={handleChangeBoard}
                    options={locationsList1}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div> */}
            <div className="academic-class-selection-code">
              <div className="delta-select-row">
                <label>Academic Year </label>
                <Select
                  value={academicYear?.value ? academicYear : "Select Year"}
                  isDisabled={!locationValue?.value}
                  placeholder="Select Academic Year"
                  onChange={handleChangeYear}
                  options={acadamicYearsList}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
                <Form.Control.Feedback type="invalid">
                  {!academicYear?.value && showErrorMessage ? (
                    <>{errorDataValue?.classId}</>
                  ) : null}
                </Form.Control.Feedback>
              </div>
              <div className="delta-select-row">
                <label>
                  Select Board/Class/Stream{" "}
                  <span className="mand-sign-field">*</span>
                </label>
                <Select
                  value={academicClass?.value ? academicClass : "Select Class"}
                  placeholder="Select Class"
                  onChange={handleChangeClass}
                  options={classesList}
                  className="delta-select"
                  classNamePrefix="delta-select"
                />
                <Form.Control.Feedback type="invalid">
                  {!academicClass?.value && showErrorMessage ? (
                    <>{errorDataValue?.classId}</>
                  ) : null}
                </Form.Control.Feedback>
              </div>
            </div>
            {showAllFields && (
              <>
                <div className="academic-class-selection-code">
                  <div className="delta-select-row">
                    <Form.Group className="signup-md22" controlId="email">
                      <Form.Label>
                        Student First Name{" "}
                        <span className="mand-sign-field">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        name="studentFirstName"
                        type="text"
                        value={signUpData?.studentFirstName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.studentFirstName ? (
                          <>{errorDataValue?.studentFirstName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="delta-select-row">
                    <Form.Group className="signup-md-lef22t" controlId="email">
                      <Form.Label>
                        Student Last Name{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="studentLastName"
                        value={signUpData?.studentLastName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.studentLastName ? (
                          <>{errorDataValue?.studentLastName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <Form.Group
                    className="admision-type-sec-con"
                    controlId="email"
                  >
                    <Form.Label>
                      Admission Type <span className="mand-sign-field">*</span>{" "}
                    </Form.Label>
                    <div className="values-sec-txt">
                      <input
                        type="radio"
                        id="Existing"
                        name="admissionType"
                        value="existing"
                        checked={
                          signUpData?.admissionType === "existing"
                            ? true
                            : false
                        }
                        onChange={(e) => handleChangeValue(e)}
                      />
                        <label htmlFor="Existing">Existing</label> {" "}
                      <input
                        type="radio"
                        id="New_Admission"
                        name="admissionType"
                        value="new"
                        checked={
                          signUpData?.admissionType === "new" ? true : false
                        }
                        onChange={(e) => handleChangeValue(e)}
                      />
                        <label htmlFor="New_Admission">New Admission</label> {" "}
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errorData?.admissionType ? (
                        <>{errorDataValue?.admissionType}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="academic-class-selection-code">
                  <div className="delta-select-row">
                    <Form.Group className="signup-md22" controlId="email">
                      <Form.Label>
                        Student ID <span className="mand-sign-field">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        name="studentId"
                        type="text"
                        value={signUpData?.studentId}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.studentId ? (
                          <>{errorDataValue?.studentId}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="delta-select-row">
                    <Form.Group className="signup-md-lef22t" controlId="email">
                      <Form.Label>
                        Annual Fee <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="feeAmount"
                        value={signUpData?.feeAmount}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.feeAmount ? (
                          <>{errorDataValue?.feeAmount}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {errorMessage && (
              <p className="error_message text_left">{errorMessage}</p>
            )}
            <div className="deltape-action-btns">
              {/* <button onClick={props?.prevStep} className="cancel-btn">
                Skip
              </button> */}
              <button onClick={handleProceed} className="confirm-btn">
                Proceed
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default SchoolSelection;
