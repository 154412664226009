import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  createClientAPI,
  getAllClients,
  getClientDetailsAPI,
  updateClientDetailsAPI,
} from "../../redux/slices/Assignments/AssignmentsService";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { acadamicYearsList, classesList, admissionList } from "../../config";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../Scenes/common";
const initValStore = {
  clientName: undefined,
  assignmentName: undefined,
  assignTO: undefined,
  startDate: undefined,
  endDate: undefined,
  status: undefined,
};
const initValError = {
  // clientName: false,
  assignmentName: false,

  // clientId: false,
};
const errorDataValue = {
  clientName: "Please Enter Client Name",
  assignmentName: "Please Enter Client Name",
  assignTO: "Please Enter Client Name",
  startDate: "Please Enter Client Name",
  endDate: "Please Enter Client Name",
  status: "Please Enter Client Name",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateClient = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const studentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : props?.match?.params?.studentId;
  const isEdit = props?.selectedStudentId ? true : false;
  const selectedStudentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);

  const { clientDetails } = useSelector((state: any) => state?.assignments);
  console.log("clientDetails", clientDetails);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (studentId) {
      dispatch(getClientDetailsAPI(studentId));
    }
  }, [selectedStudentId]);
  useEffect(() => {
    if (props?.show && !props?.selectedStudentId) {
      setSignUpData(initValStore);
    }
  }, [props?.show]);

  useEffect(() => {
    if (clientDetails || isEdit) {
      const ssDetails = clientDetails?.assignmentName
        ? clientDetails
        : props?.sDetails;
      const prevDetail = {
        ...ssDetails,
      };
      console.log("prevDetail", prevDetail);
      setSignUpData({ ...prevDetail });
    }
  }, [clientDetails, isEdit]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    setErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    setErrorMessage("");
    setShowErrorMessage(true);
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });

    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id
      const jBody = { ...signUpData, vendorId: user?._id };
      setIsLoading(true);
      if (studentId || isEdit) {
        const body = { ...jBody, _id: studentId || props?.sDetails?._id };
        const resp = (await dispatch(updateClientDetailsAPI(body))) as any;
        console.log("resp", resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();

          // if (studentId) {
          //   dispatch(getStudentDetailsAPI(studentId));
          // }

          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      } else {
        const body = { ...jBody };
        const resp = (await dispatch(createClientAPI(body))) as any;
        console.log("resp", resp);
        // await createFeeComponents(jBody, resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();
          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      }
    }
  };

  const handleCancel = () => {
    props?.handleClose();
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["studentGrade"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentGrade(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeAdmission = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["newAdmission"]: selectedOption?.label,
    });
  };

  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  console.log("signUpData", signUpData);
  const isEditText = isEdit ? "edit" : "";
  return (
    <>
      <StudentsStyled>
        <div
          className={`create-new-institute-sec create-new-institute-sec-${isEditText}`}
        >
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>{isEdit ? "Edit" : "Add"} Client</h3>
                {/* <span onClick={handleCancel}>{"< All Students"} </span> */}
              </div>

              <div>
                <div className=" deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        {" "}
                        Select Client <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Select
                        // value={studentGrade ?? "All"}
                        value={{
                          label: studentGrade?.label ?? "All",
                          value: studentGrade?.label ?? "All",
                        }}
                        placeholder="Select Assign TO"
                        onChange={handleChangeGrade}
                        options={[
                          {
                            label: "Client 1",
                            value: "Client 1",
                          },
                          {
                            label: "Client 2",
                            value: "Client 2",
                          },
                        ]}
                        className="delta-select"
                        classNamePrefix="delta-select"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.clientName ? (
                          <>{errorDataValue?.clientName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-fields-sec-content">
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Assignment Name{" "}
                          {/* <span className="mand-sign-field">*</span> */}
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.assignmentName}
                          name="assignmentName"
                          type="text"
                          value={signUpData?.assignmentName}
                          onChange={(e) => handleChangeValue(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Assign TO <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: studentGrade?.label ?? "All",
                            value: studentGrade?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangeGrade}
                          options={[
                            {
                              label: "Vinod",
                              value: "Vinod",
                            },
                            {
                              label: "User 1",
                              value: "User 1",
                            },
                          ]}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.assignTO ? (
                            <>{errorDataValue?.assignTO}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Start Date <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.startDate}
                          name="startDate"
                          type="date"
                          value={signUpData?.startDate}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.startDate ? (
                            <>{errorDataValue?.startDate}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>

                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          End Date <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.endDate}
                          name="endDate"
                          type="date"
                          value={signUpData?.endDate}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.endDate ? (
                            <>{errorDataValue?.endDate}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Status <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Select
                          // value={studentGrade ?? "All"}
                          value={{
                            label: studentGrade?.label ?? "All",
                            value: studentGrade?.label ?? "All",
                          }}
                          placeholder="Select Assign TO"
                          onChange={handleChangeGrade}
                          options={[
                            {
                              label: "In progress",
                              value: "In progress",
                            },
                            {
                              label: "Done",
                              value: "Done",
                            },
                          ]}
                          className="delta-select"
                          classNamePrefix="delta-select"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.assignTO ? (
                            <>{errorDataValue?.assignTO}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Comments{" "}
                        {/* <span className="mand-sign-field">*</span> */}
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.comments}
                        name="comments"
                        type="textarea"
                        value={signUpData?.comments}
                        onChange={(e) => handleChangeValue(e)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <p className="error_message">{errorMessage}</p>}
          <div className="actions-btn-sec actions-btn-sec-c-s align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {studentId || isEdit ? "Update Client" : "Create Client"}
            </button>
          </div>
        </div>
      </StudentsStyled>
    </>
  );
};

export default CreateClient;
