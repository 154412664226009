import React, { useEffect, useState } from "react";
import { StudentsStyled } from "./Students.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  createClientAPI,
  getAllClients,
  getClientDetailsAPI,
  updateClientDetailsAPI,
} from "../../redux/slices/Clients/ClientsService";

import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Common/Loader";
import CustomModal from "../../components/CustomModel";
import { acadamicYearsList, classesList, admissionList } from "../../config";

// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../Scenes/common";
const initValStore = {
  gstNumber: undefined,
  contactName: undefined,
  phoneNumber: undefined,
  email: undefined,
  businessAddress: undefined,
  billingAddress: undefined,
  clientName: undefined,
  clientId: undefined,
};
const initValError = {
  gstNumber: false,
  contactName: false,
  phoneNumber: false,
  email: false,
  businessAddress: false,
  billingAddress: false,
  clientName: false,
  // clientId: false,
};
const errorDataValue = {
  email: "Please Enter Phone number",
  phoneNumber: "Please Enter Last name",
  gstNumber: "Please Enter GST Number",
  contactName: "Please Enter Contact Name",
  businessAddress: "Please Enter Business Address",
  billingAddress: "Please Enter Billing Address",
  clientName: "Please Enter Client Name",
  clientId: "Please Enter Client Id",
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateClient = (props: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [studentGrade, setStudentGrade] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  // const query = useQuery();
  // const selectedTab = query.get("selectedTab");
  const studentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : props?.match?.params?.studentId;
  const isEdit = props?.selectedStudentId ? true : false;
  const selectedStudentId = props?.selectedStudentId
    ? props?.selectedStudentId
    : "";
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((state: any) => state?.auth);

  const { clientDetails } = useSelector((state: any) => state?.clients);
  console.log("clientDetails", clientDetails);
  useEffect(() => {
    triggerMixpanelEvent("Contact_Us");
    if (studentId) {
      dispatch(getClientDetailsAPI(studentId));
    }
  }, [selectedStudentId]);
  useEffect(() => {
    if (props?.show && !props?.selectedStudentId) {
      setSignUpData(initValStore);
    }
  }, [props?.show]);

  useEffect(() => {
    if (clientDetails || isEdit) {
      const ssDetails = clientDetails?.clientName
        ? clientDetails
        : props?.sDetails;
      const prevDetail = {
        ...ssDetails,
      };
      console.log("prevDetail", prevDetail);
      setSignUpData({ ...prevDetail });
    }
  }, [clientDetails, isEdit]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };

  const handleChangeValue = (e: any) => {
    setErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const handleSaveClick = async () => {
    setErrorMessage("");
    setShowErrorMessage(true);
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });

    if (isValid) {
      triggerMixpanelEvent("save_my_profile");
      //   , _id: user?._id
      const jBody = { ...signUpData, vendorId: user?._id };
      setIsLoading(true);
      if (studentId || isEdit) {
        const body = { ...jBody, _id: studentId || props?.sDetails?._id };
        const resp = (await dispatch(updateClientDetailsAPI(body))) as any;
        console.log("resp", resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();

          // if (studentId) {
          //   dispatch(getStudentDetailsAPI(studentId));
          // }

          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      } else {
        const body = { ...jBody };
        const resp = (await dispatch(createClientAPI(body))) as any;
        console.log("resp", resp);
        // await createFeeComponents(jBody, resp);
        setIsLoading(false);
        if (resp?.payload?.success) {
          props?.handleCloseSuccess();
          // setShowSuccessMessage(true);
        } else if (resp?.payload?.error) {
          setErrorMessage(resp?.payload?.error);
        }
      }
    }
  };

  const handleCancel = () => {
    props?.handleClose();
  };
  const handleEdit = () => {
    setCurrentStep("1");
  };
  const handleChangeYear = (selectedOption: any) => {
    // setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["academicYear"]: selectedOption?.label,
    });
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeGrade = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["studentGrade"]: selectedOption?.label,
    });
    // setInstituteErrorMessage("");
    setStudentGrade(selectedOption);
    // dispatch(getAllAcademicYears({ studentId: 233, locationId: 1233 }));
  };
  const handleChangeAdmission = (selectedOption: any) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["newAdmission"]: selectedOption?.label,
    });
  };

  console.log("errorDataerrorData", errorData);
  console.log("errorDataerrorData", errorDataValue);

  console.log("signUpData", signUpData);
  const isEditText = isEdit ? "edit" : "";
  return (
    <>
      <StudentsStyled>
        <div
          className={`create-new-institute-sec create-new-institute-sec-${isEditText}`}
        >
          {isLoading && <Loader />}
          <div className="create-new-institute-sec-content">
            <div className="student-info-values">
              <div className="create-new-institute-header">
                <h3>{isEdit ? "Edit" : "Add"} Client</h3>
                {/* <span onClick={handleCancel}>{"< All Students"} </span> */}
              </div>

              <div>
                <div className=" deltape-form">
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        {" "}
                        Client Name <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.clientName}
                        name="clientName"
                        type="text"
                        value={signUpData?.clientName}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.clientName ? (
                          <>{errorDataValue?.clientName}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-fields-sec-content">
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Client ID/Account Number{" "}
                          {/* <span className="mand-sign-field">*</span> */}
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.clientId}
                          name="clientId"
                          type="text"
                          value={signUpData?.clientId}
                          onChange={(e) => handleChangeValue(e)}
                        />
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          GSTIN/TAN/PAN{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.gstNumber}
                          name="gstNumber"
                          type="text"
                          value={signUpData?.gstNumber}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.gstNumber ? (
                            <>{errorDataValue?.gstNumber}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="student-fields-sec-content">
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Contact Name{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.contactName}
                          name="contactName"
                          type="text"
                          value={signUpData?.contactName}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.contactName ? (
                            <>{errorDataValue?.contactName}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row">
                      <Form.Group className="delta-signup-md" controlId="email">
                        <Form.Label>
                          Contact Number{" "}
                          <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          // autoFocus
                          isInvalid={errorData?.phoneNumber}
                          name="phoneNumber"
                          type="text"
                          maxLength={10}
                          value={signUpData?.phoneNumber}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.phoneNumber ? (
                            <>{errorDataValue?.phoneNumber}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Contact Email <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.email}
                        name="email"
                        type="text"
                        value={signUpData?.email}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.email ? <>{errorDataValue?.email}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Business Address{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.businessAddress}
                        name="businessAddress"
                        type="text"
                        value={signUpData?.businessAddress}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.businessAddress ? (
                          <>{errorDataValue?.businessAddress}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="student-info-row">
                    <Form.Group className="delta-signup-md" controlId="email">
                      <Form.Label>
                        Billing Address{" "}
                        <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        // autoFocus
                        isInvalid={errorData?.billingAddress}
                        name="billingAddress"
                        type="text"
                        value={signUpData?.billingAddress}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.billingAddress ? (
                          <>{errorDataValue?.billingAddress}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage && <p className="error_message">{errorMessage}</p>}
          <div className="actions-btn-sec actions-btn-sec-c-s align-center">
            <button className="deltape-cancel-button" onClick={handleCancel}>
              Cancel
            </button>
            <button className="deltape-button" onClick={handleSaveClick}>
              {studentId || isEdit ? "Update Client" : "Create Client"}
            </button>
          </div>
        </div>
      </StudentsStyled>
    </>
  );
};

export default CreateClient;
