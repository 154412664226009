import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";
import dayjs from "dayjs";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import step1 from "../../../img/delta/image_step1.png";
import user_info_icon from "../../../img/delta/user_info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {};
const initValError = {
  panNo: undefined,
  pancardLastName: undefined,
  pancardFirstName: undefined,
  parentGender: undefined,
  maritalStatus: undefined,
  dateOfBirth: undefined,
  email: undefined,
};

const initValErrorPancard = {
  panNo: undefined,
  pancardLastName: undefined,
  pancardFirstName: undefined,
};
const errorDataValue = {
  pancardFirstName: "Please Enter First name",
  pancardLastName: "Please Enter Last name",
  panNo: "Please enter Pan card",
  email: "Please enter Email",
  parentGender: "Please slect Parent Gender",
  maritalStatus: "Please slect Marital Status",
  dateOfBirth: "Please slect Date of Birth",
};
const UserInformation = (props: any) => {
  const { currentStep, setCurrentStep, applicationId, storePancardDetails } =
    props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  const [errorMessage, setErrorMessage] = useState("");

  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      const addr = { ...signUpData };

      setSignUpData({
        ...addr,
        panNo: applicationDetails?.panNo,
        pancardLastName: applicationDetails?.pancardLastName,
        pancardFirstName: applicationDetails?.pancardFirstName,
        parentGender: applicationDetails?.parentGender,
        maritalStatus: applicationDetails?.maritalStatus,
        dateOfBirth: applicationDetails?.dateOfBirth,
        email: applicationDetails?.email,
      });
      if (applicationDetails?.pancardDetails?.pan) {
        setIsVerified(true);
        setAgreeCheckBox(true);
      }
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    setInstituteErrorMessage("");
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
        } else {
          setInstituteErrorMessage("Please Enter Valid Pan Card.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    if (!agreeCheckBox) {
      isValid = false;
    }

    setErrorData({ ...errornew });
    return isValid;
  };

  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    setSelectedDate(datav);
    const name = "dateOfBirth";
    const value = datav;
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      [name]: value,
    });
    setErrorData({
      ...addr_error,
      [name]: false,
    });
  };
  const updatePacardDetailstoState = (pDetails: any) => {
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    setSignUpData({
      ...addr,
      dateOfBirth: pDetails?.dateOfBirth,
      parentGender:
        pDetails?.gender === "MALE"
          ? "Male"
          : pDetails?.gender === "FEMALE"
          ? "Female"
          : "Others",
    });
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  const validatePancard = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValErrorPancard } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    return isValid;
  };

  const verifyPanCard = async (e: any) => {
    if (validatePancard()) {
      if (
        pancardDetails?.pan &&
        pancardDetails?.pan?.toLowerCase() === signUpData?.panNo?.toLowerCase()
      ) {
        const pData1 = pancardDetails;
        if (
          pData1?.firstName?.toLowerCase() ===
            signUpData.pancardFirstName?.toLowerCase() &&
          pData1?.lastName?.toLowerCase() ===
            signUpData.pancardLastName?.toLowerCase()
        ) {
          setIsVerified(true);
          updatePacardDetailstoState(pData1);
        } else {
          setErrorMessage("Please Enter Valid First Name and Last Name.");
        }
      } else {
        setIsLoading(true);
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.panNo,
            applicationId: applicationId,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          const pData = resp1?.payload?.data?.result?.data?.panData;
          setPancardDetails(pData);
          storePancardDetails(pData);
          if (
            pData?.firstName?.toLowerCase() ===
              signUpData.pancardFirstName?.toLowerCase() &&
            pData?.lastName?.toLowerCase() ===
              signUpData.pancardLastName?.toLowerCase()
          ) {
            setIsVerified(true);
            updatePacardDetailstoState(pData);
          } else {
            setErrorMessage("Please Enter Valid First Name and Last Name.");
          }
        } else {
          setErrorMessage("Please Enter Valid Pan Card.");
        }
      }
    } else {
      setShowErrorMessage(true);
    }
  };
  const handleProceed = async () => {
    if (handleValidate()) {
      const body = {
        ...signUpData,
        pancardDetails: pancardDetails,
        address1: pancardDetails?.address,
        _id: applicationId,
      };
      console.log("body", body);
      setIsLoading(true);
      const resp = (await dispatch(updateApplicationAPI(body))) as any;
      setIsLoading(false);
      if (resp?.payload?.success) {
        // dispatch(getApplicationDetailById(applicationId));
        props?.nextStep();
        // props?.createApplicationSuccess(resp?.payload?.application?._id);
      } else {
        setErrorMessage("Please enter valid details!");
      }
    } else {
      setShowErrorMessage(true);
    }

    // props?.nextStep();
  };

  const disableFutureDates = (current: any) => {
    return current && current > dayjs().subtract(21, "year").endOf("day");
  };

  return (
    <>
      <div className="create-new-application-sec">
        {isLoading && <Loader />}
        <>
          <div>
            <div className="user_info_icon_sec_top">
              <img src={user_info_icon} />
              <p>User Information</p>
            </div>
            <div className="student-info-values">
              <div className="row">
                <div className="student-info-row">
                  <Form.Group className="signup-md4" controlId="email">
                    <Form.Label>
                      PAN Number <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="panNo"
                      value={signUpData?.panNo}
                      disabled={isVerified}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.panNo ? <>{errorDataValue?.panNo}</> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="student-info-row">
                  <Form.Group className="signup-md" controlId="email">
                    <Form.Label>
                      First Name <span className="mand-sign-field">*</span>{" "}
                    </Form.Label>
                    <Form.Control
                      name="pancardFirstName"
                      type="text"
                      disabled={isVerified}
                      value={signUpData?.pancardFirstName}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.pancardFirstName ? (
                        <>{errorDataValue?.pancardFirstName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="signup-md-left" controlId="email">
                    <Form.Label>
                      Last Name <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={isVerified}
                      name="pancardLastName"
                      value={signUpData?.pancardLastName}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.pancardLastName ? (
                        <>{errorDataValue?.pancardLastName}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                {isVerified ? (
                  <>
                    <div className="student-info-row admision-type-sec-con">
                      <Form.Group className="signup-md" controlId="email">
                        <Form.Label>
                          Gender
                          <span className="mand-sign-field">*</span>{" "}
                        </Form.Label>
                        <div className="values-sec-txt">
                          <input
                            type="radio"
                            id="Male"
                            name="parentGender"
                            value="Male"
                            checked={
                              signUpData?.parentGender === "Male" ? true : false
                            }
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Male">Male</label> {" "}
                          <input
                            type="radio"
                            id="Female"
                            name="parentGender"
                            value="Female"
                            checked={
                              signUpData?.parentGender === "Female"
                                ? true
                                : false
                            }
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Female">Female</label> {" "}
                          <input
                            type="radio"
                            id="Others"
                            name="parentGender"
                            value="Others"
                            checked={
                              signUpData?.parentGender === "Others"
                                ? true
                                : false
                            }
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Others">Others</label>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errorData?.parentGender ? (
                            <>{errorDataValue?.parentGender}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="signup-md-left admision-type-sec-con"
                        controlId="email"
                      >
                        <Form.Label>
                          Marital Status{" "}
                          <span className="mand-sign-field">*</span>{" "}
                        </Form.Label>
                        <div className="values-sec-txt">
                          <input
                            type="radio"
                            id="Married"
                            name="maritalStatus"
                            value="Married"
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Married">Married</label> {" "}
                          <input
                            type="radio"
                            id="Divorced"
                            name="maritalStatus"
                            value="Divorced"
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Divorced">Divorced</label> {" "}
                          <input
                            type="radio"
                            id="Others"
                            name="maritalStatus"
                            value="Others"
                            onChange={(e) => handleChangeValue(e)}
                          />
                            <label htmlFor="Others">Others</label>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errorData?.maritalStatus ? (
                            <>{errorDataValue?.maritalStatus}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="student-info-row date-of-birth-row">
                      <Form.Group className="signup-md" controlId="email">
                        <Form.Label>
                          Date of Birth{" "}
                          <span className="mand-sign-field">*</span>{" "}
                        </Form.Label>
                        <div>
                          <DatePicker
                            disabledDate={disableFutureDates}
                            onChange={onChangeDate}
                            value={
                              signUpData?.dateOfBirth
                                ? dayjs(signUpData?.dateOfBirth)
                                : signUpData?.dateOfBirth
                            }
                          />{" "}
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errorData?.dateOfBirth ? (
                            <>{errorDataValue?.dateOfBirth}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="signup-md-left" controlId="email">
                        <Form.Label>
                          Email <span className="mand-sign-field">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          value={signUpData?.email}
                          onChange={(e) => handleChangeValue(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errorData?.email ? (
                            <>{errorDataValue?.email}</>
                          ) : null}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="deltape-action-btns">
                      <button onClick={verifyPanCard} className="confirm-btn">
                        Verify Pan Card
                      </button>
                    </div>
                  </>
                )}
              </div>
              {isVerified && (
                <>
                  <div className="checkboxtacbox">
                    <input
                      onChange={aggreCheckBox}
                      id="checkboxaggrews"
                      type="checkbox"
                      checked={agreeCheckBox}
                    />
                    <label htmlFor="checkboxaggrews">
                      By proceeding, I allow affiliated banking partners to use
                      my PAN-verified name for the purpose of fetching bureau
                      details.
                    </label>
                    {agreeCheckBox}
                  </div>
                  {!agreeCheckBox && showErrorMessage && (
                    <p className="error_message">
                      Please accept Terms and Conditions{" "}
                    </p>
                  )}
                </>
              )}

              {/* {pancardDetails?.pan && (
                <div>
                  <p> Date Of Birth: {pancardDetails?.dateOfBirth}</p>
                  <p> Name: {pancardDetails?.name}</p>
                </div>
              )} */}
            </div>
          </div>
          {errorMessage && (
            <p className="error_message text_left">{errorMessage}</p>
          )}
        </>
        {isVerified && (
          <div className="actions-btn-sec">
            <div className="deltape-action-btns">
              <button onClick={props?.prevStep} className="cancel-btn">
                Back
              </button>
              <button onClick={handleProceed} className="confirm-btn">
                Proceed
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserInformation;
