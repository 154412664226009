import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";
import { DatePicker, Space } from "antd";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import salaried_img from "../../../img/delta/salaried_img.png";
import self_emp from "../../../img/delta/self_emp.png";
import user_info_icon from "../../../img/delta/user_info_icon.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const initValError = {
  monthlyIncome: undefined,
  companyName: undefined,
};
const errorDataValue = {
  monthlyIncome: "Please Enter Monthly Income",
  companyName: "Please Enter Company Name",
};
const EMIPlans = (props: any) => {
  const { currentStep, setCurrentStep, applicationId } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null as any);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [emiSelectedPlan, setEMISelectedPlan] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);
  useEffect(() => {
    if (applicationId) {
      dispatch(getApplicationDetailById(applicationId));
    }
  }, []);
  useEffect(() => {
    console.log("allInstitutes", allInstitutes);
    const allINi = [] as any;
    allInstitutes.map((iniItem: any) => {
      allINi.push({
        label: iniItem?.instituteName,
        value: iniItem?._id,
      });
    });
    setAllInstitutesList(allINi);
  }, [allInstitutes]);
  useEffect(() => {
    // setCurrentStep("2");
    if (applicationDetails?._id) {
      setEMISelectedPlan(applicationDetails?.emiSelectedPlan);
    }
  }, [applicationDetails]);

  useEffect(() => {
    const allINi = [] as any;
    const finalObj = {
      label: instituteDetails?.instituteLocation,
      value: instituteDetails?.instituteLocation,
    };
    allINi.push(finalObj);

    setAllLocationsList(allINi);
    handleChangeLocation(finalObj);
  }, [instituteDetails]);

  const onChangeValue = (e: any) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const handleChange = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setInstituteValue(selectedOption);
    // dispatch(getAllLocationsByIId({ instituteId: 233 }));
    setLocationValue({});
    setAcademicYear({});
    dispatch(getInstituteDetailsAPI(selectedOption?.value));
  };
  const handleChangeLocation = (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    setInstituteErrorMessage("");
    setLocationValue(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
    setAcademicYear({});
  };
  const handleChangeBoard = (selectedOption: any) => {
    setBoardValue(selectedOption);
  };
  const handleChangeYear = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicYear(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };
  const handleChangeClass = (selectedOption: any) => {
    setInstituteErrorMessage("");
    setAcademicClass(selectedOption);
    // dispatch(getAllAcademicYears({ instituteId: 233, locationId: 1233 }));
  };

  const handleChangeValue = async (e: any) => {
    setInstituteErrorMessage("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name === "feeAmount") {
      const value1 = e.target.value;
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  console.log("signUpData", signUpData);
  console.log("academicClass?.value", academicClass?.value);

  const handleNextAction = async () => {
    console.log("signUpData?.studentId", signUpData?.studentId);
    console.log("academicClass?.value", academicClass?.value);
    setInstituteErrorMessage("");
    // window.scrollTo(0, 0);
    console.log("academicYear", academicYear);

    if (currentStep === "1") {
      if (academicYear?.value) {
        setCurrentStep("2");
        setIsLoading(true);
        await dispatch(
          createApplicationAPI({
            instituteId: instituteValue?.value,
            instituteName: instituteValue?.label,
            locationId: locationValue?.value,
            academicYear: academicYear?.value,
            userId: user?._id,
          })
        );
        setIsLoading(false);
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "2") {
      if (academicClass?.value && signUpData?.studentId) {
        const resp1 = (await dispatch(
          getStudentDetailsByRollNumberAPI(signUpData?.studentId)
        )) as any;
        console.log("resp1", resp1);
        // if (resp?.payload?.success) {
        //   history.push("/customerDashboard");
        // }
        // return false;
        // setCurrentStep("2");
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            feeAmount: signUpData?.feeAmount,
            classId: academicClass?.value,
            studentId: signUpData?.studentId,
            _id: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp", resp);
        if (resp?.payload?.success) {
          setCurrentStep("3");
          // history.push("/customerDashboard");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "3") {
      if (
        signUpData?.pancardLastName &&
        signUpData?.pancardFirstName &&
        signUpData?.pancard
      ) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            pancardLastName: signUpData?.pancardLastName,
            pancardFirstName: signUpData?.pancardFirstName,
            pancard: signUpData?.pancard,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getPancardDetailsById({
            pan: signUpData?.pancard,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp1?.payload?.data", resp1?.payload?.data);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.data?.panData?.pan
        ) {
          setCurrentStep("4");
          setPancardDetails(resp1?.payload?.data?.result?.data?.panData);
          // history.push("/customerDashboard");
        } else {
          setInstituteErrorMessage("Please Enter Valid Pan Card.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    } else if (currentStep === "4") {
      if (signUpData?.aadhaarNo) {
        setIsLoading(true);
        const resp = (await dispatch(
          updateApplicationAPI({
            aadhaarNo: signUpData?.aadhaarNo,
            _id: applicationDetails?._id,
          })
        )) as any;
        const resp1 = (await dispatch(
          getAadhaarDetailsById({
            aadhaarNumber: signUpData?.aadhaarNo,
            applicationId: applicationDetails?._id,
          })
        )) as any;
        setIsLoading(false);
        console.log("resp1", resp1);
        console.log("resp", resp);
        if (
          resp1?.payload?.success &&
          resp1?.payload?.data?.result?.documentType
        ) {
          // setCurrentStep("5");
          setAadhaarDetails(resp1?.payload?.data?.result);
          // history.push("/customerDashboard");
        } else if (resp1?.payload?.messages[0]) {
          setInstituteErrorMessage(resp1?.payload?.messages[0]);
        } else {
          setInstituteErrorMessage("Please Enter Valid Aadhaar.");
        }
      } else {
        setInstituteErrorMessage("Please select above fields.");
      }
    }
  };
  const handleValidate = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
    var isValid = true;
    var errornew = { ...initValError } as any;
    for (var key in errornew) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    setErrorData({ ...errornew });
    return isValid;
  };
  const handleProceed = async () => {
    if (emiSelectedPlan) {
      const body = {
        emiSelectedPlan: emiSelectedPlan,
        emiAmount: calculateEMI(emiSelectedPlan),
        _id: applicationId,
      };
      console.log("body", body);
      setIsLoading(true);
      const resp = (await dispatch(updateApplicationAPI(body))) as any;
      setIsLoading(false);
      if (resp?.payload?.success) {
        // getAppDetailsIdCall();
        props?.nextStep();
      } else {
        setErrorMessage("Please enter valid Plan!");
      }
    } else {
      setErrorMessage("Please select EMI Plan");
    }
    // props?.nextStep();
  };
  const onChangeDate = (datev: any) => {
    // setErrorMessage("");
    const date2 = new Date(datev);
    console.log("date2", date2);
    console.log("onChange", datev);
    const datav = moment(datev || new Date()).format("YYYY-MM-DD");
    console.log("datav", datav);
    setSelectedDate(datav);
    console.log("allInstitutesList", allInstitutesList);
  };
  const aggreCheckBox = (e: any) => {
    const value1 = e.target.checked;
    setAgreeCheckBox(value1);
  };
  const selectEmptype = (val: any) => {
    setErrorMessage("");
    setEMISelectedPlan(val);
    console.log("valval", val);
  };
  const calculateEMI = (months: any) => {
    const principal = applicationDetails?.feeAmount;
    const annualRate = 12;
    const monthlyRate = annualRate / 12 / 100; // Monthly interest rate
    const emi =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, months)) /
      (Math.pow(1 + monthlyRate, months) - 1);
    return emi.toFixed(2); // Return EMI rounded to 2 decimal places
  };

  const insertObj = [
    {
      emiMonths: 3,
    },
    {
      emiMonths: 6,
    },
    {
      emiMonths: 9,
    },
  ];
  console.log("emiSelectedPlan", emiSelectedPlan);
  return (
    <>
      <div className="create-new-application-sec user-emi-plan-details-sec">
        {isLoading && <Loader />}
        <>
          <div>
            <div className="user-emi-plan-top">
              <h3>
                Select{" "}
                <span>EMI Plans for ₹{applicationDetails?.feeAmount}</span>
              </h3>
              <p>Number of available EMI plans: 3</p>
            </div>

            <div className="row">
              <div className="all-emi-plans-row-all">
                {insertObj.map((item) => {
                  const ammoutM = calculateEMI(item?.emiMonths) as any;
                  const finalAmo = ammoutM * item?.emiMonths;
                  return (
                    <div
                      className="all-emi-plans-row"
                      onClick={() => {
                        selectEmptype(item?.emiMonths);
                      }}
                    >
                      <div className="all-emi-plans-top-sec1">
                        <div className="d-flex">
                          <div>
                            <span className="circle-number">01</span>
                          </div>
                          <div>
                            <h6>
                              ₹{calculateEMI(item?.emiMonths)}/month X{" "}
                              {item?.emiMonths} months
                            </h6>
                          </div>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="Rented22"
                            name="empType"
                            value="salaried"
                            checked={
                              emiSelectedPlan == item?.emiMonths ? true : false
                            }
                          />
                        </div>
                      </div>
                      <div className="all-emi-plans-top-sec2">
                        <div>
                          <div className="emi-plans-top-sec-sub1">
                            <h5>Interest/month</h5>
                            <h6>Variable interest </h6>
                          </div>
                          <div>
                            <h5>Payable Today</h5>
                            <h6>₹{finalAmo?.toFixed(2)}</h6>
                          </div>
                        </div>
                      </div>
                      {item?.emiMonths === 9 && (
                        <div className="all-emi-plans-top-sec3">
                          <p>
                            <span className="dot-circle"></span>
                            <span>Most Popular</span>
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {errorMessage && (
            <p className="error_message text_left">{errorMessage}</p>
          )}
        </>
        {/* {empType && ( */}
        <div className="actions-btn-sec">
          <div className="deltape-action-btns">
            <button onClick={props?.prevStep} className="cancel-btn">
              Back
            </button>
            <button onClick={handleProceed} className="confirm-btn">
              Proceed
            </button>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default EMIPlans;
