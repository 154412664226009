import React, { useEffect, useState } from "react";
import { TransactionsStyled } from "./Transactions.styled";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import Loader from "./../../components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import Payments from "../Students/StudentModules/Payments";
import {
  getStudentDetailsAPI,
  getAllFeeComponentsAPI,
  getAllDirectPaymentsAPI,
} from "../../redux/slices/StudentManagement/StudentManagementService";
import Select from "react-select";
import { DatePicker, Space } from "antd";
import BreadCrumb from "./../../components/BreadCrumb/BreadCrumb";
const { RangePicker } = DatePicker;

const Transactions = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [signUpData, setSignUpData] = useState({});
  const [sDate, setSDate] = useState(null);
  const [eDate, setEDate] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user, isParentSite } = useSelector(
    (state) => state?.auth
  );
  const { studentDetails, allFeeComponents, allDirectPayments } = useSelector(
    (state) => state?.studentManagement
  );
  console.log("user", user);
  useEffect(() => {
    triggerMixpanelEvent("transactions_page");
    getAllPaymentsCall();
  }, []);
  useEffect(() => {
    triggerMixpanelEvent("transactions_page");
    getAllPaymentsCall();
  }, [signUpData, sDate, eDate]);
  const getAllPaymentsCall = async () => {
    let jaonBody = {};
    if (isParentSite) {
      jaonBody = { mobileNumber: user?.mobileNumber, parentId: user?._id };
    } else {
      jaonBody = { instituteId: user?._id };
    }
    if (sDate) {
      jaonBody.startDate = sDate;
    }
    if (eDate) {
      jaonBody.endDate = eDate;
    }
    if (signUpData?.reportType) {
      jaonBody.reportType = signUpData?.reportType;
    }
    if (signUpData?.status) {
      jaonBody.status = signUpData?.status;
    }
    setIsLoading(true);
    await dispatch(getAllDirectPaymentsAPI(jaonBody));
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };
  const handleDateChange = (seDate) => {
    const sDate = moment(seDate[0]).format("YYYY-MM-DD");
    const eDate = moment(seDate[1]).format("YYYY-MM-DD");
    setSDate(sDate);
    setEDate(eDate);
    const body = {
      stateDate: sDate,
      endDate: eDate,
    };
  };
  const handleChangeValue = (e) => {
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };

    setSignUpData({
      ...addr,
      [name]: value1,
    });
  };
  const handleChangeRole = (selectedOption) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["reportType"]: selectedOption?.label,
    });
  };
  const handleChangeStatus = (selectedOption) => {
    const addr = { ...signUpData };
    setSignUpData({
      ...addr,
      ["status"]: selectedOption?.label,
    });
  };

  const onChangeValue = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(e.target.value)) {
        setErrorEmail("Please enter valid email address.");
      } else {
        setErrorEmail("");
      }
    }
  };
  const rolesList = [
    {
      label: "Fee Master MIS",
      value: "Fee Master MIS",
    },
    {
      label: "Payments MIS",
      value: "Payments MIS",
    },
    {
      label: "Flex Dashboard MIS",
      value: "Flex Dashboard MIS",
    },
    {
      label: "Cred Dashboard MIS",
      value: "Cred Dashboard MIS",
    },
    {
      label: "Flex Summary",
      value: "Flex Summary",
    },
    {
      label: "Payment Page",
      value: "Payment Page",
    },
  ];
  const rolesList1 = [
    {
      label: "In Queue",
      value: "In Queue",
    },
    {
      label: "Processing",
      value: "Processing",
    },
    {
      label: "Success",
      value: "Success",
    },
    {
      label: "Failed",
      value: "Failed",
    },
  ];
  return (
    <>
      <TransactionsStyled>
        <div className="freshbag-wrapper">
          {isLoading && <Loader />}
          <div className="content getinTouchPage">
            <div className="contact">
              <div className="row">
                <BreadCrumb list={[{ title: "Transactions" }]} />
                <div className="profileinfoHeader">
                  <div>
                    <span className="edit-p-text">Transactions</span>
                  </div>
                  <p>View All Transactions</p>
                </div>
                {/* <div className="col-md-6">

                  <h2>Transactions</h2>
                  <p>View transactions & download receipts</p>
                </div> */}
              </div>
            </div>
            <div className="create-new-institute-sec-content-all">
              <div className="student-fields-sec-content-all deltape-form">
                <div className="student-info-row">
                  <Form.Group className="delta-signup-md" controlId="email">
                    <Form.Label> Date Range</Form.Label>

                    <RangePicker onChange={handleDateChange} />
                  </Form.Group>
                </div>

                <div className="delta-select-column">
                  <label>Report Type </label>
                  <Select
                    value={{
                      label: signUpData?.reportType ?? "All",
                      value: signUpData?.reportType ?? "All",
                    }}
                    placeholder="Select Report Type"
                    onChange={handleChangeRole}
                    options={rolesList}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div className="delta-select-column">
                  <label>Status </label>
                  <Select
                    value={{
                      label: signUpData?.status ?? "All",
                      value: signUpData?.status ?? "All",
                    }}
                    placeholder="Select Status"
                    onChange={handleChangeStatus}
                    options={rolesList1}
                    className="delta-select"
                    classNamePrefix="delta-select"
                  />
                </div>
                <div></div>
                {/* <div className="delta-select-column submit-filter-btn-sec">
                  <button className="deltape-button">SUBMIT</button>
                </div> */}
              </div>
            </div>
          </div>
          <Payments pageFrom="transactions" />
          {/* {allDirectPayments?.length === 0 && (
            <div className="no-results">
              <p>No Transactions available</p>
            </div>
          )} */}
        </div>
      </TransactionsStyled>
    </>
  );
};

export default Transactions;
