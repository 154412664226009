import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "./../../components/SideMenu/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import DomainNotFound from "./../DomainNotFound";
import {
  updateIsParentSiteOnly,
  updateIsAdminSiteOnly,
} from "../../redux/slices/auth/authSlice";

import "./antdstyles.css";
import "./common.css";
import "./bodyLayout.css";
const Layout = (props) => {
  useEffect(() => {}, []);
  const { error, loading, user } = useSelector(({ auth }) => auth);
  console.log("user", user);
  console.log("window.location.href", window.location.href);
  console.log("window.location.href", window.location.href);
  const urlPath = window.location.href;
  const isPrintPage = urlPath.includes("printJobCard");
  const isLoginPage = urlPath.includes("login");
  const isSignupPage = urlPath.includes("signup");
  const cUrl = window.location.pathname.toLowerCase();
  const [isInvalidDomain, setIsInvalidDomain] = useState(false);
  const dispatch = useDispatch();

  console.log("cUrl", cUrl);
  const showSideBar =
    user?._id &&
    cUrl !== "/" &&
    cUrl !== "/aboutus" &&
    cUrl !== "/contactus" &&
    cUrl !== "/home" &&
    cUrl !== "/checkjobcardstatus" &&
    cUrl !== "/termsofservice" &&
    cUrl !== "/privacypolicy" &&
    cUrl !== "/services";

  useEffect(() => {
    getSubdomainInfo();
  }, []);
  const allowDomains = ["customer", "institute", "myinstitute", "admin"];
  const getSubdomainInfo = async () => {
    try {
      const patha1 = window.location.hostname;

      let pathName = patha1?.split(".")[0] ?? "";
      console.log("pathName", pathName);
      // pathName = "focusnext";

      if (
        pathName === "localhost" ||
        pathName === "deltape" ||
        pathName === "www"
      ) {
        pathName = "";
      }
      if (pathName && pathName !== "" && !allowDomains.includes(pathName)) {
        // setIsInvalidDomain(true);
      } else if (allowDomains.includes(pathName)) {
        console.log("portal name", pathName);
        if (pathName === "customer") {
          dispatch(updateIsParentSiteOnly(true));
        } else if (pathName === "admin") {
          dispatch(updateIsAdminSiteOnly(true));
        } else if (pathName === "myinstitute" || pathName === "institute") {
          dispatch(updateIsParentSiteOnly(false));
        }
        //  else if (pathName === "institute") {
        //   dispatch(updateIsAdminSiteOnly(false));
        //   dispatch(updateIsParentSiteOnly(false));
        // }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div className={"wrapper-hide-header"}>
      {isInvalidDomain ? (
        <DomainNotFound />
      ) : (
        <>
          {cUrl === "/aa" || cUrl === "/signinaa" ? (
            <div className={"signin-page-containter"}>
              <div className="layout-container1">{props.children}</div>
            </div>
          ) : (
            <>
              <Header />
              {showSideBar ? (
                <div className="body-layout-container-sec  layout-container">
                  <div className="left-menu-container">
                    <Sidebar />
                  </div>
                  <div className="body-layout-container">
                    <div className="body-sec-content-full">
                      {props.children}
                    </div>
                    {/* <div>
                  <Footer />
                </div> */}
                  </div>
                </div>
              ) : (
                <div className="body-layout-container-sec layout-container2">
                  <div className="body-sec-content-full">{props.children}</div>
                  <div>
                    <Footer />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Layout;
