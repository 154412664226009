import { AddNewUserStyled } from "./AddNewUser.styled";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUser,
  addNewUser,
  updateCustomerData,
} from "../../redux/slices/auth/authService";
import { useHistory, useLocation } from "react-router-dom";
import { GOOGLE_MAP_URL } from "./../../config";
import CustomModal from "../../components/CustomModel";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import CloseButton from "react-bootstrap/CloseButton";
// import "./styles.css";
const initVal = {
  name: undefined,
  email: undefined,
  mobile: undefined,
  altmobile: undefined,
  address1: undefined,
  address2: undefined,
  city: undefined,
  storePostalCode: undefined,
};

const initValError = {
  name: false,
  // email: false,
  mobile: false,
  // altmobile: false,
  // address1: false,
  // address2: false,
  city: false,
  // storePostalCode: false,
};
const initValError1 = {
  name: false,
  mobile: false,
  city: false,
};

const initValErrorData = {
  name: "Please Enter Customer name",
  email: "Please Enter Email",
  mobile: "Please Enter Phone number",
  altmobile: "Please Enter Alt Phone number",
  address1: "Please Enter Street",
  // address2: "Please Enter Area / Ward",
  city: "Please Enter City",
  storePostalCode: "Please Enter Pin code",
};

const AddNewUser = (props: any) => {
  // console.log("propsprops", props);

  const [isEdit, setIsEdit] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [signUpData, setSignUpData] = useState(initVal as any);
  const [errorData, setErrorData] = useState(initValError as any);
  const [errorDataValue, setErrorDataValue] = useState(initValErrorData as any);
  const { error, loading, user } = useSelector((Route: any) => Route.auth);
  const history = useHistory();
  const [passError, setPassError] = useState("");
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (props?.show) {
  //     // onLoadGoogleMap();
  //     if (!props?.selectedEditData?.id) {
  //       setSignUpData(initVal);
  //     }
  //   }
  // }, [props?.show]);
  useEffect(() => {
    if (props?.show && props?.selectedEditData?._id) {
      const prevD = props?.selectedEditData;
      console.log("props?.selectedEditData", props?.selectedEditData);
      const addr = { ...signUpData, ...prevD };
      addr.name = prevD?.name;
      addr.mobile = prevD?.mobile;
      addr._id = prevD?._id;
      // addr.name= prevD?.name;
      // addr.name= prevD?.name;
      // addr.name= prevD?.name;
      // addr.name= prevD?.name;
      setSignUpData({
        ...addr,
      });
    } else {
      if (props?.tempUserPhoneNumber) {
        initVal.mobile = props?.tempUserPhoneNumber;
      }
      // console.log("initValinitVal", initVal);
      // console.log("propspropsprops", props);
      setSignUpData(initVal);
      setUpdateData(!updateData);
    }
  }, [props?.selectedEditData?._id, props?.show, props?.tempUserPhoneNumber]);

  const handleScriptLoad = () => {
    // const options = {
    //   componentRestrictions: { country: "ind" },
    // };
    // const autocomplete = new google.maps.places.Autocomplete(
    //   document.getElementById("addNewUserAdress1") as HTMLInputElement,
    //   options
    // );
    // autocomplete.setFields(["address_components", "formatted_address"]);
    // autocomplete.addListener("place_changed", () => {
    //   const place = autocomplete.getPlace();
    //   handlePlaceSelect(place);
    // });
  };
  const onLoadGoogleMap = () => {
    const script = document.createElement("script");
    script.src = GOOGLE_MAP_URL;
    script.id = "googleMaps";
    document.body.appendChild(script);
    script.onload = () => {
      handleScriptLoad();
    };
  };
  const handlePlaceSelect = (addressObject: any) => {
    var address1 = "";
    let postcode = "";
    let city = "";
    let state = "";
    let address2 = "";
    let address21 = "";
    let country = "";
    console.log("addressObject", addressObject);
    let fullAddress = addressObject?.formatted_address;

    for (const component of addressObject?.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name}`;
          break;
        }
        case "route": {
          address2 = component.short_name;
          break;
        }
        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }
        case "locality":
          city = component.long_name;
          break;
        case "country":
          country = component.long_name;
          break;
        case "administrative_area_level_1": {
          state = component.long_name;
          break;
        }
        case "sublocality_level_1": {
          address21 = component.long_name;
          break;
        }
      }
    }
    const AddressLine1 = address1 + " " + address2;
    console.log("fullAddress", fullAddress);
    const stateD = getPrevData();
    stateD["address"] = fullAddress;
    stateD["address1"] = AddressLine1;
    stateD["address2"] = address21;
    stateD["city"] = city;
    stateD["state"] = state;
    // stateD["county"] = country;
    const addr_error = { ...errorData };

    stateD["storePostalCode"] = postcode;

    // addr_error["address1"] = false;
    // addr_error["address2"] = false;
    addr_error["city"] = false;
    // addr_error["storePostalCode"] = false;
    setErrorData(addr_error);
    setSignUpData(stateD);
  };
  const getPrevData = () => {
    const initValStorePrev = {
      name: undefined,
      email: undefined,
      mobile: undefined,
      altmobile: undefined,
    };
    const prevVaaa = { ...signUpData };
    for (var key in initValStorePrev) {
      const key1 = `add_customer_${key}`;
      const lastNameValue1 = document.getElementById(key1) as HTMLInputElement;

      prevVaaa[key] = lastNameValue1.value;
    }

    // console.log("signUpData prevVaaa", prevVaaa);
    return prevVaaa;
  };
  const handleChangeValue = (e: any) => {
    setPassError("");
    let { name, value } = e.target;
    var value1 = value;
    if (value.trim() == "") {
      value1 = value.trim();
    }
    const addr = { ...signUpData };
    const addr_error = { ...errorData };

    if (name == "mobile" || name == "altmobile" || name === "storePostalCode") {
      const re = /^[0-9\b]+$/;
      if (value1 === "" || re.test(value1)) {
        setSignUpData({
          ...addr,
          [name]: value1,
        });
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      }
    } else if (name === "username" || name == "officeEmail") {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(value).toLowerCase())) {
        setErrorData({
          ...addr_error,
          [name]: false,
        });
      } else {
        setErrorData({
          ...addr_error,
          [name]: true,
        });
      }
    } else {
      setSignUpData({
        ...addr,
        [name]: value1,
      });
      setErrorData({
        ...addr_error,
        [name]: false,
      });
    }
  };
  const createAccountData = () => {
    setPassError("");
    var isValid = true;
    var errornew = { ...errorData };
    for (var key in initValError1) {
      if (signUpData[key] === "" || !signUpData[key]) {
        errornew[key] = true;
        isValid = false;
      }
    }
    console.log("errornew", errornew);
    setErrorData({ ...errornew });

    if (isValid) {
      // signUpData.mobile = signUpData.mobile;
      signUpData.doorNo = signUpData?.mobile;
      // signUpData.areaOrWard = signUpData?.address2;
      signUpData.town = signUpData?.city;
      // signUpData.storePostalCode = signUpData?.pinCode;
      signUpData.storeAreaOrWard = signUpData?.address2;

      makeSignUpCall(signUpData);

      console.log("signUpData 444", signUpData);
    }
  };
  const makeSignUpCall = async (data: any) => {
    setIsLoading(true);
    const resp = props?.selectedEditData?._id
      ? ((await dispatch(updateCustomerData(data))) as any)
      : ((await dispatch(addNewUser(data))) as any);
    console.log("vinodddd", resp);
    if (resp?.payload?.success) {
      setSignUpData(initVal as any);
      setIsLoading(false);
      props?.addUserSuccess(resp?.payload?.customerInfo);
    }
  };
  const validationCheck = () => {
    var formValid = false;
    console.log("signUpData", signUpData);
    console.log("errorData", errorData);
    for (var key in errorData) {
      if (signUpData[key] === "" || !signUpData[key]) {
        formValid = true;
      }
    }
    return formValid;
  };
  console.log("signUpData final", signUpData);
  // console.log("signUpData final", JSON.stringify(signUpData));
  return (
    <>
      <Modal {...props} size="lg" className="conformation-model-box1" centered>
        <div className="conformation-model">
          <AddNewUserStyled>
            <Modal.Body>
              <div>
                <div className="new-option-header">
                  <h4>
                    {" "}
                    {props?.selectedEditData?.id ? "Update" : "Add New"}{" "}
                    Customer
                  </h4>
                  <CloseButton onClick={props?.onHide} />
                </div>
                <div className="SignupContainer add-new-user-changes1">
                  <div className="row-item-sec-user">
                    <Form.Group className="signup-md" controlId="email">
                      <Form.Label>
                        Customer Name <span className="mand-sign-field">*</span>{" "}
                      </Form.Label>
                      <Form.Control
                        autoFocus
                        name="name"
                        type="text"
                        id="add_customer_name"
                        value={signUpData?.name}
                        onChange={(e) => handleChangeValue(e)}
                        isInvalid={errorData?.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.name ? <>{errorDataValue?.name}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="signup-md-left" controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        id="add_customer_email"
                        value={signUpData?.email}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.email ? <>{errorDataValue?.email}</> : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row-item-sec-user">
                    <Form.Group className="signup-md" controlId="email">
                      <Form.Label>
                        Phone Number <span className="mand-sign-field">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={10}
                        name="mobile"
                        id="add_customer_mobile"
                        value={signUpData?.mobile}
                        isInvalid={errorData?.mobile}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.mobile ? (
                          <>{errorDataValue?.mobile}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="signup-md-left" controlId="email">
                      <Form.Label>Alternative Number </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={10}
                        name="altmobile"
                        id="add_customer_altmobile"
                        value={signUpData?.altmobile}
                        onChange={(e) => handleChangeValue(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorData?.altmobile ? (
                          <>{errorDataValue?.altmobile}</>
                        ) : null}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <Form.Group className="signup-md-full">
                    <Form.Label>
                      D No / Street / Land Mark{" "}
                      {/* <span className="mand-sign-field">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="addNewUserAdress1"
                      maxLength={300}
                      name="address"
                      value={signUpData?.address}
                      isInvalid={errorData?.address}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.address ? (
                        <>{errorDataValue?.address}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="signup-md-full" controlId="email">
                    <Form.Label>
                      Area / Ward
                      {/* <span className="mand-sign-field">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={300}
                      name="address2"
                      value={signUpData?.address2}
                      isInvalid={errorData?.address2}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.address2 ? (
                        <>{errorDataValue?.address2}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="signup-md" controlId="email">
                    <Form.Label>
                      Town/City/Villege
                      <span className="mand-sign-field">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={10}
                      name="city"
                      value={signUpData?.city}
                      isInvalid={errorData?.city}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.city ? <>{errorDataValue?.city}</> : null}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="signup-md signup-md-left"
                    controlId="email"
                  >
                    <Form.Label>Pin code</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={6}
                      name="storePostalCode"
                      value={signUpData?.storePostalCode}
                      isInvalid={errorData?.storePostalCode}
                      onChange={(e) => handleChangeValue(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorData?.storePostalCode ? (
                        <>{errorDataValue?.storePostalCode}</>
                      ) : null}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {passError && <p className="errorMessage">{passError}</p>}
                  {error && !passError && (
                    <p className="errorMessage">{error}</p>
                  )}
                  <div className="add-new-submit-sec">
                    <Button
                      // disabled={validationCheck()}
                      onClick={() => {
                        createAccountData();
                      }}
                      block
                      type="submit"
                    >
                      {loading
                        ? "Loading"
                        : props?.selectedEditData?.id
                        ? "Update Customer"
                        : "Add Customer"}
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="new-option-body-actions">
                {/* <Button onClick={handleSubmitFn}>Submit</Button> */}
              </div>
            </Modal.Footer>
          </AddNewUserStyled>
        </div>
      </Modal>
    </>
  );
};
export default AddNewUser;
