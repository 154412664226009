import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  CardDeck,
  CardColumns,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import {
  getAllLocationsByIId,
  getAllBoards,
  getAllAcademicYears,
  getAllApplications,
  getAllApplicationsbyuser,
  createApplicationAPI,
  updateApplicationAPI,
  getApplicationDetailById,
} from "../../../redux/slices/PlansandPayments/PlansandPaymentsService";

import {
  getInstituteDetailsAPI,
  getAllInstitutes,
} from "../../../redux/slices/InstituteManagement/InstituteManagementService";

import {
  getStudentDetailsByRollNumberAPI,
  getPancardDetailsById,
  getAadhaarDetailsById,
} from "../../../redux/slices/StudentManagement/StudentManagementService";
import { acadamicYearsList, classesList } from "../../../config";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import step1 from "../../../img/delta/image_step1.png";
// import ProductCard from "./../../components/ProductCard";
import moment from "moment";
import { triggerMixpanelEvent } from "../../../Scenes/common";
import { id } from "date-fns/locale";
import Loader from "../../../components/Common/Loader";
const initValStore = {
  storeName: undefined,
  storeEmail: undefined,
  storeMobileNo: undefined,
  storeWebsite: undefined,
  storeGSTNumber: undefined,
  studentId: undefined,
  feeAmount: undefined,
};
const initValError = {
  storeName: undefined,
  studentId: undefined,
  feeAmount: undefined,
};
const errorDataValue = {
  pancardFirstName: "Please Enter First name",
  pancardLastName: "Please Enter Last name",
  mobileNo: "Please Enter Phone number",
  studentId: "Please enter Student ID",
  feeAmount: "Please enter Fee Amount",
  aadhaarNo: "Please enter Aadhaar No",
  pancard: "Please enter Pan card",
};
const AddChildMenuSteps = (props: any) => {
  const { currentStep, setCurrentStep } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [currentStep, setCurrentStep] = useState("1");
  const [message, setMessage] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [instituteerrorMessage, setInstituteErrorMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyPhoneNumber, setEmptyPhoneNumber] = useState(false);
  const [allInstitutesList, setAllInstitutesList] = useState([] as any);
  const [allLocationsList, setAllLocationsList] = useState([] as any);
  // const [instituteValue, setInstituteValue] = useState({} as any);
  const [instituteValue, setInstituteValue] = useState({} as any);
  const [locationValue, setLocationValue] = useState({} as any);
  const [boardValue, setBoardValue] = useState({} as any);
  const [academicYear, setAcademicYear] = useState({} as any);
  const [academicClass, setAcademicClass] = useState({} as any);
  const [signUpData, setSignUpData] = useState(initValStore as any);
  const [pancardDetails, setPancardDetails] = useState({} as any);
  const [aadhaarDetails, setAadhaarDetails] = useState({} as any);
  const [isLoading, setIsLoading] = useState(false);

  const [errorData, setErrorData] = useState(initValError as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const { error, loading, user } = useSelector((authR: any) => authR?.auth);
  const { allInstitutes, instituteDetails } = useSelector(
    (authR: any) => authR?.instituteManagement
  );
  const { applicationDetails, userAllApplications } = useSelector(
    (authR: any) => authR?.plansandPayments
  );
  console.log("instituteDetails", instituteDetails);
  console.log("applicationDetails", applicationDetails);
  console.log("userAllApplications", userAllApplications);

  console.log("allInstitutesList", allInstitutesList);
  return (
    <>
      <div className="fee-payment-sec-main">
        <h5>Fee Payment To</h5>
        <p className="Fee-Payment-text-sec">
          {applicationDetails?.instituteName}, {applicationDetails?.locationId}
        </p>
        <div className="main-content-left-static">
          <div>
            <div>
              <div className="circle circle1">1</div>
              <div className="line"></div>
            </div>
            <div className="step-content">
              <h6>User Information</h6>
              <p>Enter basic information of the user </p>
            </div>
          </div>
          <div>
            <div>
              <div className="circle circle2">2</div>
              <div className="line line2"></div>
            </div>
            <div className="step-content">
              <h6>Terms & Conditions</h6>
              <p>Accept the T&C for the use of application </p>
            </div>
          </div>
          <div>
            <div>
              <div className="circle circle3">3</div>
              {/* <div className="line"></div> */}
            </div>
            <div className="step-content">
              <h6>Fee Payment</h6>
              <p>The payment is made to the chosen institute </p>
            </div>
          </div>
        </div>
        <p className="contact-sup-team">
          <strong>Contact Support Team</strong>
        </p>
        <p className="contact-sup-team-t">
          +91 805 080 7711 <br />
          contact@deltape.in
        </p>
      </div>
    </>
  );
};

export default AddChildMenuSteps;
